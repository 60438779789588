import React, { useState } from "react";
import _ from "lodash";
import cuid from "cuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import FeatureStatistics from "./FeatureStatistics";

const ViewFeature = (props) => {
  const [activeTab, setActiveTab] = useState("statistics");
  let progressPerc = 0;
  if (props.feature) {
    progressPerc = props.feature.status === "Complete" ? 100 : props.feature.status === "In Progress" ? 50 : 0;
  }

  return (
    <div className="column">
      <div className="box">
        <progress className="progress is-primary is-small" value={progressPerc} max="100">
          {progressPerc}%
        </progress>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_title", "Title")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <h6 className="subtitle is-6">{props.feature.title}</h6>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_description", "Description")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <h6 className="subtitle is-6">{props.feature.description}</h6>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_project", "Project")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <h6 className="subtitle is-6">
              {props.feature.project && props.feature.project.name}
            </h6>
          </div>
        </div>
      </div>

      {props.feature.release && (
        <div className="field is-horizontal">
          <div className="field-label">
            <label className="label">{getFormattedMessage("feature.field_release", "Release")}</label>
          </div>
          <div className="field-body">
            <div className="field is-expanded">
              <h6 className="subtitle is-6">
                {props.feature.release && props.feature.release.name}
              </h6>
            </div>
          </div>
        </div>
      )}

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_createdby", "Created By")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <h6 className="subtitle is-6">
              {props.feature.user && props.feature.user.name}
            </h6>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_status", "Status")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <h6 className="subtitle is-6">
              {props.feature.status || getFormattedMessage("feature.status_notstarted", "Not Started")}
            </h6>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">{getFormattedMessage("feature.field_attachments", "Attachments")}</label>
        </div>
        <div className="field-body">
          {props.feature.attachments && props.feature.attachments.length > 0 ? (
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  {_.map(props.feature.attachments, (attachment) => (
                    <div className="file" key={cuid()}>
                      <label className="file-label">
                        <span className="file-cta">
                          <span className="file-icon">
                            <FontAwesomeIcon icon={faDownload} />
                          </span>
                          <span className="">{getFormattedMessage("fileattachment.download_file_label", "Download file...")}</span>
                        </span>
                        <span className="file-name">{attachment}</span>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <span>{getFormattedMessage("none.label", "None")}</span>
          )}
        </div>
      </div>

      <p>
        <br />
        <br />
        <br />
        <br />
      </p>

      <hr />

      <div className="tabs">
        <ul>
          <li className={activeTab === "statistics" ? "is-active" : ""}>
            <a onClick={() => setActiveTab("statistics")}>{getFormattedMessage("statistics.label", "Statistics")}</a>
          </li>
        </ul>
      </div>

      {activeTab === "statistics" && (
        <FeatureStatistics
          projectId={(props.feature.project && props.feature.project._id) || ""}
          releaseId={(props.feature.release && props.feature.release._id) || ""}
        />
      )}
    </div>
  );
};

export default ResourceProvider(ViewFeature);
