import React, { useContext, useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import DatePicker, { registerLocale } from "react-datepicker";
import { withRouter } from "react-router-dom";
import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

import { CREATE_RELEASE_MUTATION } from "./../../graphql/mutations";
import { QUERY_RELEASES } from "./../../graphql/queries";

import "react-datepicker/dist/react-datepicker.css";

const EditReleaseForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [name, setName] = useState((props.release && props.release.name) || "");
  const [description, setDescription] = useState((props.release && props.release.description) || "");
  const [project, setProject] = useState((props.release && props.release.project && props.release.project._id) || "");
  const [startDate, setStartDate] = useState((props.release && new Date(props.release.startDate)) || new Date());
  const [endDate, setEndDate] = useState((props.release && new Date(props.release.endDate)) || new Date());
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    if (validateResult) {
      if (name.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.release_name_required", "Error: Release name is required.");
      } else if (name.trim().length > 250) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.field_name_max_chars_exceeded",
          "Error: The value you have entered exceeds the maximum character limit of 250 characters for this field. Please enter less than 250 characters.");
      }
    }

    if (validateResult && (!project || project.trim() === "")) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.release_project_required", "Error: Release project is required.");
    }

    if (validateResult && !startDate) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.release_start_date_required", "Error: Release start date is required.");
    }

    if (validateResult && !endDate) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.release_end_date_required", "Error: Release end date is required.");
    }

    if (validateResult && startDate > endDate) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.release_end_date_cannot_be_before_start_date", "Error: Release end date cannot be before start date.");
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const [createRelease, { loading, error: createError }] = useMutation(CREATE_RELEASE_MUTATION, {
    refetchQueries: [{ query: QUERY_RELEASES }],
    onCompleted: (data) => {
      const { success, message, release } = data.createRelease;
      if (!success) {
        setError(true);
        setErrorMsg(message);
        return;
      }

      if (createError) {
        setError(true);
        setErrorMsg(getResourceById(state, "release.error_create_update", "Encountered server error when creating/updating release."));
        return;
      }

      props.history.push("/releases");
    },
    onError: (error) => {
      setError(true);
      setErrorMsg(`${getResourceById(state, "error.encountered_server_error_message", "Encountered server error")}: ${error.message}`);
    },
  });

  const handleNewRelease = async (event) => {
    event.preventDefault();

    const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setError(true);
      setErrorMsg(validationMsg);
      return;
    }

    try {
      const isNew = !props.release;
      createRelease({
        variables: {
          name,
          description,
          project,
          startDate,
          endDate,
          isNew,
        },
      });
    } catch (err) {
      console.log("Error when creating/updating release. Error -", err);
      setError(true);
      setErrorMsg(getResourceById(state, "release.error_create_update", "Encountered server error when creating/updating release."));
      return;
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    props.history.push("/releases");
  };

  return (
    <form>
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{errorMsg}</label>
        </div>
      )}
      <div className="field">
        <label className="label">{getFormattedMessage("name.label", "Name")}</label>
        <div className="control">
          <input onChange={(e) => setName(e.target.value)} value={name} className="input" type="text" required="" disabled={!!props.release} placeholder={getResourceById(state, "name.placeholder", "Enter Name")} />
        </div>
        <p className="help">{getFormattedMessage("release.name_is_required", "Release name is required")}</p>
      </div>

      <div className="field">
        <div className="columns">
          <div className="column">
            <label className="label">{getFormattedMessage("release.field_startdate", "Start Date")}</label>
            <div className="control has-icons-left has-icons-right">
              <DatePicker locale={state.locale.value} todayButton={getResourceById(state, "today.label", "Today")} selected={startDate} onChange={(date) => setStartDate(date)} />
              <p className="help">{getFormattedMessage("release.start_date_is_required", "Start date is required")}</p>
            </div>
          </div>
          <div className="column">
            <label className="label">{getFormattedMessage("release.field_enddate", "End Date")}</label>
            <div className="control has-icons-left has-icons-right">
              <DatePicker locale={state.locale.value} todayButton={getResourceById(state, "today.label", "Today")} selected={endDate} onChange={(date) => setEndDate(date)} />
              <p className="help">{getFormattedMessage("release.end_date_is_required", "End date is required")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("description.label", "Description")}</label>
        <div className="control">
          <textarea onChange={(e) => setDescription(e.target.value)} value={description} className="textarea" required="" placeholder={getResourceById(state, "description.placeholder", "Enter description")}></textarea>
        </div>
        <p className="help">{getFormattedMessage("release.description_is_optional", "Release description is optional")}</p>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("project.label", "Project")}</label>
        <div className="control">
          <div className="select">
            <select onChange={(e) => setProject(e.target.value)} defaultValue={project}>
              <option value="">------------ {getResourceById(state, "select.project", "Select project")} ------------</option>
              {_.map(props.projects, (p) => (
                <option key={p._id} value={p._id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <p className="help">{getFormattedMessage("release.project_is_required", "Project for this release. Project is required")}</p>
        <br />
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button onClick={handleNewRelease} className="button is-link">
            {getFormattedMessage("submit.button", "Submit")}
          </button>
        </div>
        <div className="control">
          <button type="reset" onClick={handleCancel} className="button is-link is-light">
          {getFormattedMessage("cancel.button", "Cancel")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResourceProvider(EditReleaseForm));
