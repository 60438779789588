import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { withRouter, Link, useParams } from "react-router-dom";
import EditProjectForm from './EditProjectForm';
import LeftMenu from './../LeftMenu/LeftMenu';
import TopNav from './../TopNav/TopNav';
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_PROJECT } from './../../graphql/queries';

const EditProject = () => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState("projects");
    const [isBurgerActive, setIsBurgerActive] = useState(false);
    
    const { loading, error, data } = useQuery(QUERY_PROJECT, { variables: { id } });

    if (error) return <div>{getFormattedMessage("error.encountered_error_message", "Encountered error")} - {error}</div>
    return (
        <div>
            <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-4-tablet is-3-desktop is-2-widescreen">
                        <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
                    </div>
                    <div className="column">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li><Link to="/">{getFormattedMessage("home.label", "Home")}</Link></li>
                                <li><Link to="/projects" aria-current="page">{getFormattedMessage("projects.label", "Projects")}</Link></li>
                                <li className="is-active">&nbsp;&nbsp;{getFormattedMessage("edit.label", "Edit")}</li>
                            </ul>
                        </nav>
                        <div className="container is-fluid">
                            <section>
                                <div>
                                    <h2 className="title">{getFormattedMessage("edit.project_label", "Edit Project")}</h2>
                                    {loading ? <div>{getFormattedMessage("loading.message", "loading...")}</div> : <EditProjectForm project={data.project} />}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(defaultHeadComponent(ResourceProvider(EditProject)));
