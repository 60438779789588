import React from 'react';
import _ from 'lodash';
import { useQuery } from "@apollo/client";

import { DATETIME_FORMATS } from "../../graphql/queries";

const DateField = (props) => {
  const { timestamp, locale } = props;
  const { loading, error, data } = useQuery(
    DATETIME_FORMATS,
    {
      variables: {
        timestamp,
      },
      fetchPolicy: "network-only", // fetch from network.
    }
  );

  if (loading) return <>...</>;
  if (error) {
    console.error('Error when fetching datetime format from server. Error - ', error);
    return <></>;
  };

  let dateString = '';
  if (data && data.dateTimeFormats) {
    const filteredResults = _.filter(data.dateTimeFormats, df => df.locale.toLowerCase() === locale.toLowerCase());
    if (filteredResults && filteredResults.length > 0) {
      dateString = filteredResults[0].value;
    }
  }

  return (
    <>
      {dateString}
    </>
  );
}

export default DateField;
