module.exports = {
  options: {
    turnOffCurrentLocaleDetection: true,
  },
  dataserver: {
    url: "http://localhost:3004",
  },
  graphQLServer: {
    uri: "https://featurematrix.graphql.devops-automation.net/graphql",
  },
};
