import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";
import DashboardCharts from "./DashboardCharts";
import DashboardReleases from "./DashboardReleases";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const Dashboard = (props) => {
  const [currentPage, _] = useState("dashboard");
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  return (
    <div>
      <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-4-tablet is-3-desktop is-2-widescreen">
            <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
          </div>
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">{getFormattedMessage("home.label", "Home")}</Link>
                </li>
                <li className="is-active">
                  <Link to="#" aria-current="page">
                  {getFormattedMessage("dashboard.label", "Dashboard")}
                  </Link>
                </li>
              </ul>
            </nav>
            <section className="hero is-info welcome is-small">
              <div className="hero-body">
                <div className="container">
                  <h1 className="title">{getFormattedMessage("dashboard.label", "Dashboard")}</h1>
                  <h2 className="subtitle">{getFormattedMessage("dashboard.view_features_releases", "view features and releases metrics!")}</h2>
                </div>
              </div>
            </section>
            <p>&nbsp;</p>
            <div className="container is-fluid">
              <div className="columns">
                <DashboardCharts />
                <DashboardReleases />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(defaultHeadComponent(ResourceProvider(Dashboard)));
