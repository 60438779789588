import React, { useEffect, useState, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BootstrapScriptsLoader from "../ScriptLoaders/BootstrapScriptsLoader";
import { register } from './../../serviceWorker';

const HTMLPlayground = (props) => {
    const [showPage1, setShowPage1] = useState(true);
    const [showPage2, setShowPage2] = useState(false);
    const [showPage3, setShowPage3] = useState(false);
    const [showPage4, setShowPage4] = useState(false);
    const [showCollapsibleSection1, setShowCollapsibleSection1] = useState(true);
    const [showCollapsibleSection2, setShowCollapsibleSection2] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [attachedShadowRoot, setAttachedShadowRoot] = useState(false);
    const headerTitleRef = useRef(null);
    const subHeaderTitleRef = useRef(null);
    const shadowRootRef = useRef(null);

    const bootstrapCSS = "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/css/bootstrap.min.css";
    
    useEffect(() => {
        // preload CSS files using a service worker.
        const cssFiles = [
          bootstrapCSS,
        ];
    
        const urls = cssFiles.map(cssFile => new URL(cssFile, window.location).href);
        register({ urls });
      }, []);

    const onFormSubmitClicked = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
    }

    const onCollapsibleSection1Clicked = () => {
        const newValue = !showCollapsibleSection1;
        setShowCollapsibleSection1(newValue);
        setShowCollapsibleSection2(false);
    }

    const onCollapsibleSection2Clicked = () => {
        const newValue = !showCollapsibleSection2;
        setShowCollapsibleSection2(newValue);
        setShowCollapsibleSection1(false);
    }

    const onCard1Button1Clicked = () => {
        alert('Card 1, button 1 was clicked!')
    }

    const onCard1Button2Clicked = () => {
        alert('Card 1, button 2 was clicked!')
    }

    const onCard2Button1Clicked = () => {
        alert('Card 2, button 1 was clicked!')
    }

    const onCard2Button2Clicked = () => {
        alert('Card 2, button 2 was clicked!')
    }

    const onOpenJavascriptAlertClicked = () => {
        alert('Hello there! You just opened a javascript alert!');
    }

    const onOpenTabClicked = () => {
        window.open('https://featurematrix.devops-automation.net', '_blank');
    }

    const onOpenModalClicked = () => {
        setShowModal(true);
    }

    const onCloseModal = () => {
        setShowModal(false);
    }

    const onPage1Clicked = () => {
        // shadowRoot is defined at body level. hide when selected page is not page 4.
        shadowRootRef.current.style = "display: none";
        setShowPages(true, false, false, false);
        setFormSubmitted(false);
        headerTitleRef.current.innerHTML = 'HTML Playground - Page 1';
        subHeaderTitleRef.current.innerHTML = 'This page shows HTML form elements. This HTML page has been setup for automation testing and there is no server-side logic or localization on this page.';
    }

    const onPage2Clicked = () => {
        // shadowRoot is defined at body level. hide when selected page is not page 4.
        shadowRootRef.current.style = "display: none";
        setShowPages(false, true, false, false);
        setFormSubmitted(false);
        headerTitleRef.current.innerHTML = 'HTML Playground - Page 2';
        subHeaderTitleRef.current.innerHTML = 'This page shows text, table and image component examples. This HTML page has been setup for automation testing and there is no server-side logic or localization on this page.';
    }

    const onPage3Clicked = () => {
        // shadowRoot is defined at body level. hide when selected page is not page 4.
        shadowRootRef.current.style = "display: none";
        setShowPages(false, false, true, false);
        setFormSubmitted(false);
        headerTitleRef.current.innerHTML = 'HTML Playground - Page 3';
        subHeaderTitleRef.current.innerHTML = 'This page shows Javascript code examples. This HTML page has been setup for automation testing and there is no server-side logic or localization on this page.';
    }

    const onPage4Clicked = () => {
        // shadowRoot is defined at body level. show when selected page is page 4.
        shadowRootRef.current.style = "";
        setShowPages(false, false, false, true);
        setFormSubmitted(false);
        headerTitleRef.current.innerHTML = 'HTML Playground - Page 4';
        subHeaderTitleRef.current.innerHTML = 'This page shows Shadow DOM, IFrame example. This HTML page has been setup for automation testing and there is no server-side logic or localization on this page.';

        // attach shadow root if not already attached.
        if (!attachedShadowRoot) {
            // Add content and associated style to the shadow root
            const shadowRoot = shadowRootRef.current.attachShadow({ mode: 'open' });    
            const style = document.createElement('style');
            style.textContent = 'p { border: 1px solid #53a8b6; background-color: #79c2d0; font-size: 1.5em; padding: 20px;}';
            shadowRoot.appendChild(style);

            const p = document.createElement('p');
            p.textContent = 'This element is part of shadow DOM and gets styling defined within the shadow DOM.';
            shadowRoot.appendChild(p);

            // Set component state for attached shadow root to true.
            setAttachedShadowRoot(true);
        }
    }

    const setShowPages = (show1, show2, show3, show4) => {
        setShowPage1(show1);
        setShowPage2(show2);
        setShowPage3(show3);
        setShowPage4(show4);
    }

    const showModalStyle = showModal? { display: 'block' } : { display: 'none' };
    const showModalClassName = showModal? 'modal fade show' : 'modal fade';

    return (
        <>
        <Helmet>
            {/* Add styling specific to this page. */}
            <meta name="description" content="HTML playground for automation testing courses" />
            <link rel="stylesheet" href={bootstrapCSS} integrity="sha512-rt/SrQ4UNIaGfDyEXZtNcyWvQeOq0QLygHluFQcSjaGB04IxWhal71tKuzP6K8eYXYB6vJV4pHkXcmFGGQ1/0w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        </Helmet>
        {/* Add scripts specific to this page. */}
        <BootstrapScriptsLoader />
        <div className="container-fluid">
		<div className="jumbotron">
            <Link className="btn btn-light" style={{ float: "right" }} to="/">Back to Main</Link >
			<h1 id="headerTitle" ref={headerTitleRef} className="display-4">HTML Playground - Page 1</h1>
			<p id="subHeaderTitle" ref={subHeaderTitleRef} className="lead">This page shows HTML form elements. This HTML page has been setup for automation testing and there is no server-side logic or localization on this page.</p>
			<hr className="my-4" />
			<p>Navigate to the different pages to view various HTML elements on this site.</p>
			<ul className="nav">
				<li className="nav-item">
					<a id="linkHome" className="nav-link active" href="#" onClick={onPage1Clicked}>Home/Form</a>
				</li>
				<li className="nav-item">
					<a id="linkTextComponent" className="nav-link" href="#" onClick={onPage2Clicked}>Text/Image Components</a>
				</li>
				<li className="nav-item">
					<a id="linkJSComponent" className="nav-link" href="#" onClick={onPage3Clicked}>Javascript Components</a>
				</li>
				<li className="nav-item">
					<a id="linkIFrame" className="nav-link" href="#" onClick={onPage4Clicked}>Shadow DOM, IFrame</a>
				</li>
			</ul>
		  </div>
		{formSubmitted && <>
            {/* -- Form submitted section -- */}
            <div id="sectionFormSubmitted" className="row">
                <div className="col-md-12">
                    <h4>
                        Form was submitted successfully!
                    </h4>
                </div>
            </div>
        </>}
		{showPage1 && <>
            {/* -- Form section -- */}
            <form is="sampleForm" name="sampleForm">
                <div id="sectionForm" className="row">
                <div className="col-md-12">
                    <h3>
                        Form Input Example
                    </h3>
                        <div className="form-group">
                            <label htmlFor="sampleInputEmail1">
                                Email address
                            </label>
                            <input type="email" className="form-control" id="sampleInputEmail1" />
                        </div>
                        <div className="form-group">					 
                            <label htmlFor="sampleInputPassword1">
                                Password
                            </label>
                            <input type="password" className="form-control" id="sampleInputPassword1" />
                        </div>
                        <div className="form-group">					 
                            <label htmlFor="sampleDateInput1">
                                Start Date
                            </label>
                            <input type="date" className="form-control" id="sampleDateInput1" defaultValue="2022-11-11" />
                        </div>
                        <div className="form-group">					 
                            <label htmlFor="sampleNumberInput1">
                                Number of Days
                            </label>
                            <input type="number" className="form-control" id="sampleNumberInput1" step="1" defaultValue="1" max="100" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sampleTextArea1">
                                Special instructions for this project
                            </label>
                            <textarea id="sampleTextArea1" className="form-control" placeholder="Your message here" cols="20" rows="5" minLength="100" maxLength="500"></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="departments">Choose a department:</label>
                            <select className="form-control" name="departments" id="departments">
                                <option value="">--Please choose an option--</option>
                                <option value="0">Accounts & Finance</option>
                                <option value="1">Human Resources</option>
                                <option value="2">IT & Operations</option>
                                <option value="3">Marketing & Sales</option>
                                <option value="4">Software Development</option>
                            </select>
                        </div>
                        <div className="form-group">					 
                            <label htmlFor="sampleInputFile">
                                File input
                            </label>
                            <input type="file" className="form-control-file" id="sampleInputFile" />
                            <p className="help-block">
                                (Attach files related to this project)
                            </p>
                        </div>
                        <div className="checkbox">					 
                            <label>
                                <input type="checkbox" /> I agree to this websites terms and conditions
                            </label>
                        </div> 
                        <button className="btn btn-primary" onClick={onFormSubmitClicked}>
                            Submit
                        </button>
                </div>
                <div className="col-md-12 mt3">
                    &nbsp;
                </div>
                </div>            
            </form>
        </>}
        {showPage2 && <>
            {/* -- Text/Image component -- */}
            <div id="sectionTextImage" className="row">
                <div className="col-md-12">
                    <h3>
                        Text Component Examples
                    </h3>
                    <div className="row">
                        <div className="col-md-4">
                            <p>
                                Lorem Ipsum dolor sit amet, consectetur adipiscing elit, <strong>sed do eiusmod tempor</strong> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, <i>quis nostrud exercitation ullamco</i> laboris nisi ut aliquip ex <u>ea commodo consequat</u>. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <ul>
                                <li className="list-item">
                                    Jived fox nymph grabs quick waltz.
                                </li>
                                <li className="list-item">
                                    Glib jocks quiz nymph to vex dwarf.
                                </li>
                                <li className="list-item">
                                    Sphinx of black quartz, judge my vow.
                                </li>
                                <li className="list-item">
                                    How vexingly quick daft zebras jump!
                                </li>
                                <li className="list-item">
                                    The five boxing wizards jump quickly.
                                </li>
                                <li className="list-item">
                                    Jackdaws love my big sphinx of quartz.
                                </li>
                                <li className="list-item">
                                    Pack my box with five dozen liquor jugs.
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <ol>
                                <li className="list-item">
                                    Jived fox nymph grabs quick waltz.
                                </li>
                                <li className="list-item">
                                    Glib jocks quiz nymph to vex dwarf.
                                </li>
                                <li className="list-item">
                                    Sphinx of black quartz, judge my vow.
                                </li>
                                <li className="list-item">
                                    How vexingly quick daft zebras jump!
                                </li>
                                <li className="list-item">
                                    The five boxing wizards jump quickly.
                                </li>
                                <li className="list-item">
                                    Jackdaws love my big sphinx of quartz.
                                </li>
                                <li className="list-item">
                                    Pack my box with five dozen liquor jugs.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <h3>
                        Table Component Example
                    </h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Project Name
                                </th>
                                <th>
                                    Approval Status
                                </th>
                                <th>
                                    Approved Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1
                                </td>
                                <td>
                                    Project Name 01
                                </td>
                                <td>
                                    Default
                                </td>
                                <td>
                                    11/01/2022
                                </td>
                            </tr>
                            <tr className="table-active">
                                <td>
                                    2
                                </td>
                                <td>
                                    Project Name 02
                                </td>
                                <td>
                                    Approved
                                </td>
                                <td>
                                    11/01/2022
                                </td>
                            </tr>
                            <tr className="table-success">
                                <td>
                                    3
                                </td>
                                <td>
                                    Project Name 03
                                </td>
                                <td>
                                    Declined
                                </td>
                                <td>
                                    11/02/2022
                                </td>
                            </tr>
                            <tr className="table-warning">
                                <td>
                                    4
                                </td>
                                <td>
                                    Project Name 04
                                </td>
                                <td>
                                    Pending
                                </td>
                                <td>
                                    11/03/2022
                                </td>
                            </tr>
                            <tr className="table-danger">
                                <td>
                                    5
                                </td>
                                <td>
                                    Project Name 05
                                </td>
                                <td>
                                    Started
                                </td>
                                <td>
                                    11/04/2022
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav>
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" href="#">Previous</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">1</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">2</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">3</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">4</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">5</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                    <h3>
                        Cards with Images Example
                    </h3>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <img className="card-img-top" alt="Bootstrap Thumbnail First" src="https://via.placeholder.com/600x200/0369a1/f0f9ff/?text=This+Is+Text+On+Image" />
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Card Title 1
                                    </h5>
                                    <p className="card-text">
                                        The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.
                                    </p>
                                    <p>
                                        <a className="btn btn-primary" href="#" onClick={onCard1Button1Clicked}>Button 1</a> <a className="btn" href="#" onClick={onCard1Button2Clicked}>Button 2</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <img className="card-img-top" alt="Bootstrap Thumbnail Second" src="https://via.placeholder.com/600x200/0369a1/f0f9ff/?text=This+Is+Text+On+Image" />
                                <div className="card-body">
                                    <h5 className="card-title">
                                        Card Title 2
                                    </h5>
                                    <p className="card-text">
                                        Jived fox nymph grabs quick waltz. Jived fox nymph grabs quick waltz. Jived fox nymph grabs quick waltz. Jived fox nymph grabs quick waltz. Jived fox nymph grabs quick waltz.
                                    </p>
                                    <p>
                                        <a className="btn btn-primary" href="#" onClick={onCard2Button1Clicked}>Button 1</a> <a className="btn" href="#" onClick={onCard2Button2Clicked}>Button 2</a>
                                    </p>
                                </div>
                            </div>
                        </div>				
                    </div>
                </div>
                <div className="col-md-12 mt3">
                    &nbsp;
                </div>
            </div>        
        </>}
        {showPage3 && <>
            {/* -- Javascript code -- */}
            <div id="sectionJSCode" className="row">
                <div className="col-md-12">
                    <h3>
                        Javascript Examples
                    </h3>
                    <a id="modal-01" href="#modal-container-01" onClick={onOpenModalClicked} role="button" className="btn btn-secondary mr-3 mb-3" data-toggle="modal">Click to open modal</a>				
                    <a id="open-tab-01" href="#" onClick={onOpenTabClicked} role="button" className="btn btn-secondary mb-3" data-toggle="modal">Click to open tab</a>				
                    <div className={showModalClassName} id="modal-container-01" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={showModalStyle}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="myModalLabel">
                                        Modal title
                                    </h5> 
                                    <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Modal body content goes here...
                                </div>
                                <div className="modal-footer">
                                    
                                    <button type="button" className="btn btn-primary">
                                        Save changes
                                    </button> 
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={onCloseModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-success alert-dismissable">
                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                        </button>
                        <h4>
                            Alert!
                        </h4> <strong>Successful!</strong> Things are looking good here, operation succeeded. <a href="#" onClick={onOpenJavascriptAlertClicked} className="alert-link">Click Here To Open Javascript Alert</a>
                    </div>
                    <div id="card-20537">
                        <div className="card">
                            <div className="card-header">
                                <a className="card-link" data-toggle="collapse" onClick={onCollapsibleSection1Clicked} href="#card-element-1">Collapsible Section #1</a>
                            </div>
                            {showCollapsibleSection1 && <div id="card-element-1">
                                <div className="card-body">
                                    The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.
                                </div>
                            </div>}
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <a className="collapsed card-link" data-toggle="collapse" onClick={onCollapsibleSection2Clicked} href="#card-element-2">Collapsible Section #2</a>
                            </div>
                            {showCollapsibleSection2 && <div id="card-element-2">
                                <div className="card-body">
                                    The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog. The quick brown fox jumps over the lazy dog.
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt3">
                    &nbsp;
                </div>
            </div>        
        </>}
        <div id="shadowRootContainer" ref={shadowRootRef}></div>	
        {showPage4 && <>
            {/* -- IFrame -- */}
            <div id="sectionIFrame" className="row">
                <div className="col-md-12">
                    <p>This element is NOT part of shadow DOM and gets styling defined in HTML page</p>
                    <h3>
                        IFrame Example
                    </h3>
                    <iframe name="myIFrame" src="https://www.example.com" height="500" width="100%"></iframe>
                </div>
            </div>
        </>}

    </div>
        </>
    );
}
  
export default withRouter(HTMLPlayground);
  