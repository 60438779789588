import React from "react";
import { withRouter, Link } from "react-router-dom";

const Statistics = (props) => {
  const { title, count, buttonTitle, buttonToLink } = props;
  return (
    <div className="level-item has-text-centered">
      <div>
        <p className="heading">{title}</p>
        <p className="title">{count}</p>
        <br />
        <button className="button is-link is-light mb-6">
          <Link to={buttonToLink}>&nbsp;{buttonTitle}&nbsp;</Link>
        </button>
      </div>
    </div>
  );
};

export default withRouter(Statistics);
