export default function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case "LOGIN_USER":
            return {
                ...state,
                sessionUser: payload,
            }
        case "IS_LOGGED_IN":
            return {
                ...state,
                isAuth: payload,
            }
        case "SIGNOUT_USER":
            return {
                ...state,
                sessionUser: null,
                isAuth: false,
            }
        case "SET_LOCALE":
            return {
                ...state,
                locale: payload,
            }
        default:
            return state;
    }
}