import React, { useContext, useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

import { CREATE_SERVICE_MUTATION } from "./../../graphql/mutations";
import { QUERY_SERVICES } from "./../../graphql/queries";

import "react-datepicker/dist/react-datepicker.css";

const EditServiceForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [name, setName] = useState((props.service && props.service.name) || "");
  const [description, setDescription] = useState((props.service && props.service.description) || "");
  const [project, setProject] = useState((props.service && props.service.project && props.service.project._id) || "");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    if (validateResult) {
      if (name.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.service_name_required", "Error: Service name is required.");
      } else if (name.trim().length > 250) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.field_name_max_chars_exceeded",
          "Error: The value you have entered exceeds the maximum character limit of 250 characters for this field. Please enter less than 250 characters.");
      }
    }

    if (validateResult && (!project || project.trim() === "")) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.service_project_required", "Error: Service project is required.");
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const [createService, { loading, error: createError }] = useMutation(CREATE_SERVICE_MUTATION, {
    refetchQueries: [{ query: QUERY_SERVICES }],
    onCompleted: (data) => {
      const { success, message, service } = data.createService;
      if (!success) {
        setError(true);
        setErrorMsg(message);
        return;
      }

      if (createError) {
        setError(true);
        setErrorMsg(getResourceById(state, "service.error_create_update", "Encountered server error when creating/updating service."));
        return;
      }

      props.history.push("/services");
    },
    onError: (error) => {
      setError(true);
      setErrorMsg(`${getResourceById(state, "error.encountered_server_error_message", "Encountered server error")}}: ${error.message}`);
    },
  });

  const handleNewService = async (event) => {
    event.preventDefault();

    const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setError(true);
      setErrorMsg(validationMsg);
      return;
    }

    try {
      const isNew = !props.service;
      createService({
        variables: {
          name,
          description,
          project,
          isNew,
        },
      });
    } catch (err) {
      console.log("Error when creating/updating service. Error -", err);
      setError(true);
      setErrorMsg(getResourceById(state, "service.error_create_update", "Encountered server error when creating/updating service."));
      return;
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    props.history.push("/services");
  };

  return (
    <form>
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{errorMsg}</label>
        </div>
      )}
      <div className="field">
        <label className="label">{getFormattedMessage("name.label", "Name")}</label>
        <div className="control">
          <input onChange={(e) => setName(e.target.value)} value={name} className="input" type="text" required="" disabled={!!props.service} placeholder={getResourceById(state, "name.placeholder", "Enter Name")} />
        </div>
        <p className="help">{getFormattedMessage("service.name_is_required", "Service name is required")}</p>
      </div>
      <div className="field">
        <label className="label">{getFormattedMessage("description.label", "Description")}</label>
        <div className="control">
          <textarea onChange={(e) => setDescription(e.target.value)} value={description} className="textarea" required="" placeholder={getResourceById(state, "description.placeholder", "Enter description")}></textarea>
        </div>
        <p className="help">{getFormattedMessage("service.description_is_optional", "Service description is optional")}</p>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("project.label", "Project")}</label>
        <div className="control">
          <div className="select">
            <select onChange={(e) => setProject(e.target.value)} defaultValue={project}>
              <option value="">------------ {getResourceById(state, "select.project", "Select project")} ------------</option>
              {_.map(props.projects, (p) => (
                <option key={p._id} value={p._id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <p className="help">{getFormattedMessage("service.project_is_required", "Project for this service. Project is required")}</p>
        <br />
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button onClick={handleNewService} className="button is-link">
            {getFormattedMessage("submit.button", "Submit")}
          </button>
        </div>
        <div className="control">
          <button type="reset" onClick={handleCancel} className="button is-link is-light">
          {getFormattedMessage("cancel.button", "Cancel")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResourceProvider(EditServiceForm));
