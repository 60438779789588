import React, { useContext, useReducer } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { registerLocale } from 'react-datepicker';

import ProtectedRoute from "./ProtectedRoute";

// Import Components for Routes
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login/Login";
import Projects from "./Projects/Projects";
import Releases from "./Releases/Releases";
import Features from "./Features/Features";
import Services from "./Services/Services";
import NewProject from "./Projects/NewProject";
import NewRelease from "./Releases/NewRelease";
import NewService from "./Services/NewService";
import NewFeature from "./Features/NewFeature";
import EditProject from "./Projects/EditProject";
import EditRelease from "./Releases/EditRelease";
import EditService from "./Services/EditService";
import SignUp from "./SignUp/SignUp";
import Settings from "./Settings/Settings";
import Terms from "./Terms/Terms";
import HTMLPlayground from "./Playground/HTMLPlayground";

import Context from "./../context";
import reducer from "./../reducer";

import enAU from 'date-fns/locale/en-AU';
import esES from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt-BR';
import enCA from 'date-fns/locale/en-CA';
import zhCN from 'date-fns/locale/zh-CN';
import frFR from 'date-fns/locale/fr';
import hiIN from 'date-fns/locale/hi';
import itIT from 'date-fns/locale/it';
import ruRU from 'date-fns/locale/ru';
import ukUA from 'date-fns/locale/uk';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';

// Register locales with `react-datepicker` and `DateFNS` packages 
// Locale strings registered here are the same as the ones used in locale dropdown in the application
// Registering locales is necessary for setting 'locale' property in react-datepicker <DatePicker> component

// Locale strings used here are in language-REGION format. Refer here - https://icu4c-demos.unicode.org/icu-bin/locexp

registerLocale('en-AU', enAU);   // English (Australia)
registerLocale('es-ES', esES);   // Spanish (Spain) 
registerLocale('pt-BR', ptBR);   // Portuguese (Brazil) 
registerLocale('en-CA', enCA);   // English (Canada) 
registerLocale('zh-CN', zhCN);   // Chinese (China) 
registerLocale('fr-FR', frFR);   // French (France) 
registerLocale('hi-IN', hiIN);   // Hindi (India) 
registerLocale('it-IT', itIT);   // Italian (Italy) 
registerLocale('ru-RU', ruRU);   // Russian (Russia)  
registerLocale('uk-UA', ukUA);   // Ukrainian (Ukraine)  
registerLocale('en-GB', enGB);   // English (UK)   
registerLocale('en-US', enUS);   // English (USA)

const App = (props) => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { client } = props;
  // console.log("state from reducer -", { state });
  return (
    <BrowserRouter>
      <Context.Provider value={{ state, dispatch }}>
        <Switch>
          <ProtectedRoute exact path="/" component={() => <Dashboard client={client} />} />
          <Route exact path="/login" component={() => <Login client={client} />} />
          <Route exact path="/signup" component={() => <SignUp client={client} />} />
          <Route exact path="/terms" component={() => <Terms client={client} />} />
          <Route exact path="/playground" component={() => <HTMLPlayground client={client} />} />
          <ProtectedRoute exact path="/projects" component={() => <Projects client={client} />} />
          <ProtectedRoute exact path="/projects/new" component={() => <NewProject client={client} />} />
          <ProtectedRoute exact path="/projects/edit/:id" component={() => <EditProject client={client} />} />
          <ProtectedRoute exact path="/releases" component={() => <Releases client={client} />} />
          <ProtectedRoute exact path="/releases/new" component={() => <NewRelease client={client} />} />
          <ProtectedRoute exact path="/releases/edit/:id" component={() => <EditRelease client={client} />} />
          <ProtectedRoute exact path="/features" component={() => <Features client={client} />} />
          <ProtectedRoute exact path="/features/new" component={() => <NewFeature client={client} />} />
          <ProtectedRoute exact path="/services" component={() => <Services client={client} />} />
          <ProtectedRoute exact path="/services/new" component={() => <NewService client={client} />} />
          <ProtectedRoute exact path="/services/edit/:id" component={() => <EditService client={client} />} />
          <ProtectedRoute exact path="/settings" component={() => <Settings client={client} />} />
        </Switch>
      </Context.Provider>
    </BrowserRouter>
  );
};

export default App;
