// This file contains typeDefs and resolvers for client side state managed by Apollo client.
// REFERENCE: https://www.apollographql.com/docs/tutorial/local-state/

import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

export const resolver = {};