import React, { useState, useContext, useEffect, useRef } from "react";
import { isEmail } from 'validator';
import { withRouter } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";
import { LOGIN_USER_MUTATION } from "./../../graphql/mutations";
import { IS_LOGGED_IN } from "./../../graphql/queries";
import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

const LoginForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const emailInput = useRef(null);

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  // Apollo Client library includes the ability to use GraphQL queries for local state management.
  // Apollo react puts ApolloClient instance within React's context.
  // To access this context we can use the 'useApolloClient' hook.
  const client = useApolloClient();
  const [loginUser] = useMutation(LOGIN_USER_MUTATION, {
    onCompleted: (data) => {
      // console.log('Login response -', { data });
      if (data && data.loginUser) {
        if (!data.loginUser.success) {
          setError(true);
          setErrorMsg(data.loginUser.message);
          return;
        }

        // login success. store jwt in localstorage.
        const { name, token } = data.loginUser.user;
        localStorage.setItem("user-token", token);
        localStorage.setItem("user-name", name);
        client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
        props.history.push("/");
      } else {
        setError(true);
        setErrorMsg(getResourceById(state, "error.server_encountered_error", "Server encountered error. Please try after some time."));
        return;
      }
    },
  });

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setError(false);
    setErrorMsg("");
    emailInput.current.focus();
  }


  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    if (validateResult) {
      if (email.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.login_email_required", "Error: Email is required.");
      } else if (email.trim().length > 250) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.email_max_chars_exceeded",
           "Error: The email you have entered exceeds the maximum character limit of 250 characters. Please enter a valid email address.");
      } else if (!isEmail(email.trim())) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.login_email_must_be_valid", "Error: Please enter a valid email address.");
      }
    } 

    if (validateResult) {
      if (password.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.login_password_required", "Error: Password is required.");
      } else if (password.trim().length < 8 || password.trim().length > 25) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.password_min_max_char_limit", "Error: Password must be between 8 and 25 characters long.");
      }
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const handleLogin = (event) => {
    event.preventDefault();

        const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setError(true);
      setErrorMsg(validationMsg);
      return;
    }

    try {
      loginUser({ variables: { email, password } });
    } catch (err) {
      console.log("Error during login. Error -", err);
    }
  };

  return (
    <form>
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{errorMsg}</label>
        </div>
      )}
      <div className="field">
        <label className="label">{getFormattedMessage("email.label", "Email")}</label>
        <div className="control has-icons-left has-icons-right">
          <input ref={emailInput} className="input" type="email" placeholder={getResourceById(state, "email.placeholder", "Enter Email")} onChange={(e) => setEmail(e.target.value)} value={email} required="" />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        </div>
        <p className="help">{getFormattedMessage("email.validation_required", "Email field is required")}</p>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("password.label", "Password")}</label>
        <div className="control has-icons-left has-icons-right">
          <input className="input" type="password" value={password} placeholder="**********" onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" required="" />
          <span className="icon is-small is-left">
            <i className="fas fa-user"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
        <p className="help">{getFormattedMessage("password.validation_required", "Password field is required")}</p>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link" onClick={handleLogin}>
          {getFormattedMessage("submit.button", "Submit")}
          </button>
        </div>
        <div className="control">
          <button onClick={resetForm} type="reset" className="button is-link is-light">
          {getFormattedMessage("cancel.button", "Cancel")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResourceProvider(LoginForm));
