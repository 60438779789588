import React, { useState } from 'react';
import SignUpForm from './SignUpForm';
import TopNav from './../TopNav/TopNav';
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const SignUp = () => {
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    return (
        <div>
            <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
            <section className="hero is-info welcome is-small">
                <div className="hero-body">
                    <div className="container is-fluid">
                        <div className="columns">
                            &nbsp;
                            </div>
                    </div>
                </div>
            </section>
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-one-third">
                        <p>&nbsp;</p>
                    </div>
                    <div className="column is-one-third">
                        <section>
                            <div className="container my-6">
                                <h2 className="title">{getFormattedMessage("signup.button", "Sign Up")}</h2>
                                <SignUpForm />
                            </div>
                        </section>
                    </div>
                    <div className="column is-one-third">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default defaultHeadComponent(ResourceProvider(SignUp));
