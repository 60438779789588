import React, { useContext, useState, useEffect, useRef  } from "react";
import { isEmail } from 'validator';
import { useMutation, useApolloClient } from "@apollo/client";
import { withRouter, Link } from "react-router-dom";
import { REGISTER_USER_MUTATION } from "./../../graphql/mutations";
import { IS_LOGGED_IN } from "./../../graphql/queries";
import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

const SignUpForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [regCode, setRegCode] = useState("");
  const [agree, setAgree] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const regCodeInput = useRef(null);

  useEffect(() => {
    regCodeInput.current.focus();
  }, []);

  const client = useApolloClient();
  const [signUp, { loading, error }] = useMutation(REGISTER_USER_MUTATION, {
    onCompleted: (data) => {
      const { success, message } = data.registerUser;
      if (!success) {
        setValidationError(true);
        setValidationErrorMsg(message);
        return;
      }

      const { name, token } = data.registerUser.user;
      localStorage.setItem("user-token", token);
      localStorage.setItem("user-name", name);
      client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: true } });
      props.history.push("/");
    },
  });

  const resetForm = () => {
    setName("");
    setEmail("");
    setPassword("");
    setRegCode("");
    setAgree(false);
    setValidationError(false);
    setValidationErrorMsg("");
    regCodeInput.current.focus();
  }

  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    if (validateResult && regCode.trim() === "") {
      validateResult = false;
      validationMsg = getResourceById(state, "error.signup_registration_code_required", "Error: Registration code is required.");
    }

    if (validateResult && name.trim() === "") {
      validateResult = false;
      validationMsg = getResourceById(state, "error.signup_name_required", "Error: Name is required.");
    }

    if (validateResult) {
      if (email.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.signup_email_required", "Error: Email is required.");
      } else if (email.trim().length > 250) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.email_max_chars_exceeded",
           "Error: The email you have entered exceeds the maximum character limit of 250 characters. Please enter a valid email address.");
      } else if (!isEmail(email.trim())) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.login_email_must_be_valid", "Error: Please enter a valid email address.");
      }
    } 

    if (validateResult) {
      if (password.trim() === "") {
        validateResult = false;
        validationMsg = getResourceById(state, "error.login_password_required", "Error: Password is required.");
      } else if (password.trim().length < 8 || password.trim().length > 25) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.password_min_max_char_limit", "Error: Password must be between 8 and 25 characters long.");
      }
    }

    if (validateResult && !agree) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.signup_terms_conditions_required", "Error: You did not agree to terms and conditions.");
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setValidationError(true);
      setValidationErrorMsg(validationMsg);
      return;
    }

    try {
      signUp({
        variables: {
          name,
          email,
          password,
          regCode,
        },
      });
    } catch (err) {
      console.log(getResourceById(state, "error.signup_message", "Error during signup. Error -"), err);
      setValidationError(true);
      setValidationErrorMsg(
        getResourceById(state, "error.signup_try_after_some_time", "Error during signup! Please try again after some time.")
      );
      return;
    }
  };

  return (
    <form>
      {validationError && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{validationErrorMsg}</label>
        </div>
      )}
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{getFormattedMessage("signup.validation_error", "Error during sign-up!")}</label>
        </div>
      )}
      {loading && (
        <div className="field">
          <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
        </div>
      )}
      <div className="field">
        <label className="label">{getFormattedMessage("registration_code.label", "Registration Code")}</label>
        <div className="control">
          <input
            ref={regCodeInput}
            onChange={(e) => setRegCode(e.target.value)}
            value={regCode}
            className="input"
            type="text"
            required=""
            placeholder={getResourceById(state, "registration_code.placeholder", "Code you received from administrator")}
          />
        </div>
        <p className="help">{getFormattedMessage("registration_code.validation_required", "Registration Code field is required")}</p>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("name.label", "Name")} </label>
        <div className="control">
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="input"
            type="text"
            required=""
            placeholder={getResourceById(state, "name.placeholder", "Enter name")}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("email.label", "Email")}</label>
        <div className="control has-icons-left has-icons-right">
          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="input"
            type="email"
            placeholder={getResourceById(state, "email.placeholder", "Enter Email")}
            required=""
          />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle"></i>
          </span>
        </div>
        <p className="help">{getFormattedMessage("email.validation_required", "Email field is required")}</p>
      </div>

      <div className="field">
        <label className="label">{getFormattedMessage("password.label", "Password")}</label>
        <div className="control has-icons-left has-icons-right">
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            className="input"
            type="password"
            placeholder="**********"
            autoComplete="current-password"
            required=""
          />
          <span className="icon is-small is-left">
            <i className="fas fa-user"></i>
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-check"></i>
          </span>
        </div>
        <p className="help">{getFormattedMessage("password.validation_required", "Password field is required")}</p>
      </div>

      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input
              onChange={(e) => setAgree(e.target.checked)}
              checked={agree}
              id="chkAgree"
              type="checkbox"
              required=""
            />
            &nbsp;{getFormattedMessage("agree.label", "I agree to the")}{" "}
            <Link to="/terms" target="_blank">
              {getFormattedMessage("terms_and_conditions.label", "terms and conditions")}
            </Link>
          </label>
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button onClick={handleSignup} className="button is-link">
            {getFormattedMessage("submit.button", "Submit")}
          </button>
        </div>
        <div className="control">
          <button onClick={resetForm} type="reset" className="button is-link is-light">
            {getFormattedMessage("cancel.button", "Cancel")}            
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResourceProvider(SignUpForm));
