import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const Settings = (props) => {
  const [currentPage, _] = useState("settings");
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const onDeleteClicked = () => {
    setShowDeleteMessage(true);
  };

  const onHideDeleteNotification = () => {
    setShowDeleteMessage(false);
  };

  return (
    <div>
      <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-4-tablet is-3-desktop is-2-widescreen">
            <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
          </div>
          <div className="column">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/">{getFormattedMessage("home.label", "Home")}</Link>
                </li>
                <li className="is-active">
                  <Link to="#" aria-current="page">
                    {getFormattedMessage("settings.label", "Settings")}
                  </Link>
                </li>
              </ul>
            </nav>
            <section className="hero is-info welcome is-small">
              <div className="hero-body">
                <div className="container">
                  <h1 className="title">{getFormattedMessage("settings.label", "Settings")}</h1>
                </div>
              </div>
            </section>
            <p className="mt-1">&nbsp;</p>
            <article className="message is-danger">
              <div className="message-body">
                {getFormattedMessage("settings.bodytext_you_can_reset_app_data", "You can reset application data created by currently logged-in user, by using this delete option.")} <br />
                <strong>{getFormattedMessage("caution.label", "Caution")}</strong> : {getFormattedMessage("settings.bodytext_deleting_user_data_irreversible", "Deleting user application data is an irreversible process. Once the data is deleted, you'll not be able to recover it again.")} <br />
                <br />
                {getFormattedMessage("settings.bodytext_following_data_will_be_deleted", "Following application data will be deleted using this option")}:-
                <ol className="message-body mt-4 mb-4">
                  <li className="ml-3">{getFormattedMessage("settings.bodytext_delete_message_projects_will_be_deleted", "Projects created by you will be deleted")}</li>
                  <li className="ml-3">{getFormattedMessage("settings.bodytext_delete_message_services_will_be_deleted", "Services created by you will be deleted")}</li>
                  <li className="ml-3">{getFormattedMessage("settings.bodytext_delete_message_releases_will_be_deleted", "Releases created by you will be deleted")}</li>
                  <li className="ml-3">{getFormattedMessage("settings.bodytext_delete_message_features_will_be_deleted", "Features created by you will be deleted")}</li>
                  <li className="ml-3">{getFormattedMessage("settings.bodytext_delete_message_login_will_not_be_deleted", "Your user login will NOT be deleted. You'll be able to login with your existing credentials and continue using this application.")}</li>
                </ol>{" "}
                <div className="control mt-4">
                  <button onClick={onDeleteClicked} className="button is-secondary">
                    {getFormattedMessage("settings.button_permanently_delete", "Permanently Delete User Application Data")}
                  </button>
                </div>
              </div>
            </article>
            {showDeleteMessage && (
              <div className="notification is-warning is-light">
                <button className="delete" onClick={onHideDeleteNotification}></button>
                <i>{getFormattedMessage("settings.message_feature_under_implementation", "This feature is currently under implementation.")} </i>{getFormattedMessage("settings.message_no_changes_were_made", "No changes were made to user application data.")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(defaultHeadComponent(ResourceProvider(Settings)));
