import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";
import ResourceProvider, {getFormattedMessage} from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_RELEASES } from "./../../graphql/queries";

import ReleasesTable from "./ReleasesTable";

const Releases = (props) => {
  const [currentPage, _] = useState("releases");
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const { loading, error, data } = useQuery(QUERY_RELEASES);

  // console.log('Release: data -', data);

  if (error) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("releases.error_loading", "Encountered error when loading releases!")}
        </label>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="field">
        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </div>
    );
  }

  if (data && data.releases && data.releases.releases) {
    return (
      <div>
        <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-4-tablet is-3-desktop is-2-widescreen">
              <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
            </div>
            <ReleasesTable releases={data.releases.releases} />
          </div>
        </div>
      </div>
    );
  }
};

export default defaultHeadComponent(ResourceProvider(Releases));
