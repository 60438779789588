import { gql } from "@apollo/client";

export const LOGIN_USER_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      code
      success
      message
      user {
        name
        token
      }
    }
  }
`;

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser($name: String!, $email: String!, $password: String!, $regCode: String!) {
    registerUser(user: { name: $name, email: $email, password: $password, regCode: $regCode }) {
      code
      success
      message
      user {
        name
        token
      }
    }
  }
`;

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($name: String!, $budget: Float!, $currency: String!, $isNew: Boolean!) {
    createProject(project: { name: $name, budget: $budget, currency: $currency, isNew: $isNew }) {
      code
      success
      message
      project {
        _id
        name
        budget
        currency
        createdAt
      }
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($id: String!, $cursor: String, $limit: Int, $sortBy: String) {
    deleteProject(id: $id, cursor: $cursor, limit: $limit, sortBy: $sortBy) {
      code
      success
      message
      projects {
        _id
        name
        user {
          _id
          name
        }
        createdAt
      }
    }
  }
`;

export const CREATE_RELEASE_MUTATION = gql`
  mutation CreateRelease($name: String!, $description: String!, $project: String!, $startDate: DateTime!, $endDate: DateTime!, $isNew: Boolean!) {
    createRelease(release: { name: $name, description: $description, project: $project, startDate: $startDate, endDate: $endDate, isNew: $isNew }) {
      code
      success
      message
      release {
        _id
        name
      }
    }
  }
`;

export const DELETE_RELEASE_MUTATION = gql`
  mutation DeleteRelease($id: String!, $cursor: String, $limit: Int, $sortBy: String) {
    deleteRelease(id: $id, cursor: $cursor, limit: $limit, sortBy: $sortBy) {
      code
      success
      message
      releases {
        _id
        name
        description
        startDate
        endDate
      }
    }
  }
`;
export const CREATE_SERVICE_MUTATION = gql`
  mutation CreateService($name: String!, $description: String!, $project: String!, $isNew: Boolean!) {
    createService(service: { name: $name, description: $description, project: $project, isNew: $isNew }) {
      code
      success
      message
      service {
        _id
        name
      }
    }
  }
`;

export const DELETE_SERVICE_MUTATION = gql`
  mutation DeleteService($id: String!, $cursor: String, $limit: Int, $sortBy: String) {
    deleteService(id: $id, cursor: $cursor, limit: $limit, sortBy: $sortBy) {
      code
      success
      message
      services {
        _id
        name
        description
        project {
          _id
          name
        }
        user {
          _id
          name
        }
        createdAt
      }
    }
  }
`;
export const CREATE_FEATURE_MUTATION = gql`
  mutation CreateFeature($title: String!, $description: String!, $project: String!, $release: String, $status: String, $startDate: DateTime, $endDate: DateTime, $attachments: [String], $isNew: Boolean!) {
    createFeature(feature: { title: $title, description: $description, project: $project, release: $release, status: $status, startDate: $startDate, endDate: $endDate, attachments: $attachments, isNew: $isNew }) {
      code
      success
      message
      feature {
        _id
        title
        description
        project {
          _id
          name
        }
        release {
          _id
          name
        }
        user {
          _id
          name
        }
        status
        startDate
        endDate
        attachments
      }
    }
  }
`;

export const DELETE_FEATURE_MUTATION = gql`
  mutation DeleteFeature($id: String!, $cursor: String, $limit: Int, $sortBy: String) {
    deleteFeature(id: $id, cursor: $cursor, limit: $limit, sortBy: $sortBy) {
      code
      success
      message
    }
  }
`;

export const UPDATE_FEATURE_STATUS_MUTATION = gql`
  mutation UpdateFeatureStatus($id: String!, $status: String!) {
    updateFeatureStatus(id: $id, status: $status) {
      code
      success
      message
      feature {
        _id
        title
        description
        project {
          _id
          name
        }
        release {
          _id
          name
        }
        user {
          _id
          name
        }
        status
        startDate
        endDate
        attachments
      }      
    }
  }
`
