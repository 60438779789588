import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { QUERY_FEATURES } from "./../../graphql/queries";
import ResourceProvider, {getFormattedMessage, getResourceById} from "./../ResourceProvider";
import FeatureListItem from "./FeaturesListItem";
import Context from "../../context";

const FeaturesList = (props) => {
  const { state, dispatch } = useContext(Context);
  const [selectedFeatureId, setSelectedFeatureId] = useState("");
  const [features, setFeatures] = useState(props.features || []);
  const [sortBy, setSortBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (props.refetchedFeatures) {
      setFeatures(props.refetchedFeatures);
    }
  }, [props.refetchedFeatures]);

  const onFeatureSelected = (feature) => {
    setSelectedFeatureId(feature._id);
    // console.log('selected feature -', feature._id);
    if (props.onFeatureSelected) {
      props.onFeatureSelected(feature);
    }
  };

  const onShowMoreClicked = (e) => {
    e.preventDefault();
    props.fetchMore &&
      props.fetchMore({
        query: QUERY_FEATURES,
        variables: {
          cursor: features.cursor,
          limit: props.limit || 10,
          sortBy,
        },

        /* Sample Previous Result Structure:
                - <Entry>:
                   - cursor: "5f6d8daffa4d71253cf7dea9"
                   - features: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
                   - hasMore: true
                   - __typename: "FeatureResponse"
            */

        /* Sample FetchMore Result Structure: (since this is gql response there is a top-level "features" item)
                - features:
                   - cursor: "5f6d8daffa4d71253cf7dea9"
                   - features: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
                   - hasMore: true
                   - __typename: "FeatureResponse"
            */

        updateQuery: (previousResult, { fetchMoreResult }) => {
          // console.log('updateQuery | previousResult =', previousResult);
          // console.log('updateQuery | fetchMoreResult =', fetchMoreResult);

          const previousEntry = previousResult.features;
          const newFeatures = fetchMoreResult.features.features;
          const newCursor = fetchMoreResult.features.cursor;
          const newHasMore = fetchMoreResult.features.hasMore;
          const allFeatures = [...previousEntry.features, ...newFeatures];

          const queryResponse = {
            cursor: newCursor,
            hasMore: newHasMore,
            features: allFeatures,
            __typename: previousEntry.__typename,
          };

          setFeatures(queryResponse);
          return queryResponse;
        },
      });
  };

  const onSortItemChanged = async (e) => {
    e.preventDefault();
    const val = e.target.value;
    // console.log("onSortItemChanged | e.target.value =", val);
    if (val !== "") {
      setSortBy(val);
      if (props.refetch) {
        setShowLoading(true);
        const variables = {
          limit: props.limit || 10,
          sortBy: val,
          searchTerm,
        };
        const { data } = await props.refetch(variables);
        props.updateQuery((prevResult, { variables }) => {
          return data;
        });
        // console.log('refetch result |', data);
        setFeatures(data.features);
        setShowLoading(false);
      }
    }
  };

  const onSearchInputKeyPress = async (e) => {
    // console.log("e.key =", e.key);
    if (e.key === "Enter") {
      //   console.log("Enter key pressed.");
      const val = e.target.value.trim();
      setSearchTerm(val);
      if (props.refetch) {
        setShowLoading(true);
        const variables = {
          limit: props.limit || 10,
          sortBy,
          searchTerm: val,
        };
        const { data } = await props.refetch(variables);
        props.updateQuery((prevResult, { variables }) => {
          return data;
        });
        // console.log('refetch result |', data);
        setFeatures(data.features);
        setShowLoading(false);
      }
    }
  };

  //   console.log("FeaturesList | features =", features);
  return (
    <div className="column is-4">
      <table className="table">
        <thead>
          <tr>
            <th>
              <div className="columns">
                <div className="column is-8">
                  <div className="control has-icons-right">
                    <input className="input is-small" type="text" placeholder={getResourceById(state, "features.search_features_label", "Search features")} onKeyPress={onSearchInputKeyPress} />
                    <span className="icon is-right">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                </div>
                <div className="column">
                  <div className="field-body is-pulled-right">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-small is-fullwidth">
                          {/* Server supports time-based sorting for query results. 
                                                        Include time-based column eg. date, ObjectId(), etc here. 
                                                        Server currently does NOT handle non-time based sort columns */}
                          <select onChange={onSortItemChanged}>
                            <option value="">--- {getResourceById(state, "features.sort_label", "Sort Items By")} ---</option>
                            <option value="createdAt">{getResourceById(state, "features.sort_created_ascending", "Created Ascending")}</option>
                            <option value="-createdAt">{getResourceById(state, "features.sort_created_descending", "Created Descending")}</option>
                            <option value="startDate">{getResourceById(state, "features.sort_start_date_ascending", "Start Date Ascending")}</option>
                            <option value="-startDate">{getResourceById(state, "features.sort_start_date_descending", "Start Date Descending")}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        {showLoading ? (
          <tbody>
            <tr>
              <td>
                <div className="columns">
                  <div className="column is-half is-offset-one-fifth">
                    <button className="button is-loading">{getFormattedMessage("loading.message", "loading...")}</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {features &&
              _.map(features.features, (feature) => {
                return (
                  <tr key={feature._id}>
                    <td className={selectedFeatureId === `${feature._id}` ? "has-background-link-light" : ""} onClick={() => onFeatureSelected(feature)}>
                      <FeatureListItem feature={feature} />
                    </td>
                  </tr>
                );
              })}
            {features && features.hasMore && (
              <tr>
                <td>
                  <nav className="pagination is-rounded is-centered" role="navigation">
                    <ul className="pagination-list">
                      <li>
                        <a className="pagination-link" onClick={onShowMoreClicked}>
                          &nbsp;&nbsp;{getFormattedMessage("show.more_label", "show more")}&hellip;&nbsp;&nbsp;
                        </a>
                      </li>
                    </ul>
                  </nav>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default ResourceProvider(FeaturesList);
