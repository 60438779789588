import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { DELETE_FEATURE_MUTATION, UPDATE_FEATURE_STATUS_MUTATION } from './../../graphql/mutations';
import { QUERY_FEATURES } from './../../graphql/queries';

import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";
import ConfirmModal from './../Dialogs/ConfirmDialog';

const FeatureTitle = (props) => {
    const { state, dispatch } = useContext(Context);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const [serverError, setServerError] = useState(false);
    const [serverErrorMsg, setServerErrorMsg] = useState("");

    const [deleteFeature, { loading, error }] = useMutation(DELETE_FEATURE_MUTATION, {
        refetchQueries: [{ query: QUERY_FEATURES }],
        onCompleted: data => {
            const { success, message } = data.deleteFeature;
            if (!success) {
                setServerError(true);
                setServerErrorMsg(message);
                return;
            }

            if (error) {
                setServerError(true);
                setServerErrorMsg(getResourceById(state, "feature.error_deletion", "Encountered server error when deleting feature."));
                return;
            }

            if (props.onDeleteButtonClicked) {
                props.onDeleteButtonClicked();
                return;
            }
        }
    });

    const [updateFeatureStatus, { loadingU, errorU }] = useMutation(UPDATE_FEATURE_STATUS_MUTATION, {
        refetchQueries: [{ query: QUERY_FEATURES }],
        onCompleted: data => {
            const { success, message } = data.updateFeatureStatus;
            if (!success) {
                setServerError(true);
                setServerErrorMsg(message);
                return;
            }

            if (errorU) {
                setServerError(true);
                setServerErrorMsg(getResourceById(state, "feature.error_update_status", "Encountered server error when updating feature status."));
                return;
            }

            if (props.onStatusUpdated) {
                props.onStatusUpdated();
                return;
            }
        }
    });

    const onEditButtonClicked = (e) => {
        e.preventDefault();
        if (props.onEditButtonClicked) {
            props.onEditButtonClicked();
        }
    }

    const confirmDelete = () => {
        setShowConfirmDelete(true);
    }

    const onDeleteButtonClicked = () => {
        try {
            deleteFeature({
                variables: {
                    id: props.feature._id,
                }
            });
            setShowConfirmDelete(false);
        } catch (err) {
            console.log('Error when deleting feature. Error -', err);
            setServerError(true);
            setServerErrorMsg(getResourceById(state, "feature.error_deletion", "Encountered server error when deleting feature."));
            setShowConfirmDelete(false);
            return;
        }
    }

    const onFeatureStatusChanged = (status) => {
        try {
            updateFeatureStatus({
                variables: {
                    id: props.feature._id,
                    status,
                }
            });
        } catch (err) {
            console.log('Error when updating feature status. Error -', err);
            setServerError(true);
            setServerErrorMsg(getResourceById(state, "feature.error_update_status", "Encountered server error when updating feature status."));
            return;
        }
    }

    return (
        <div className="column">
            <div className="columns">
                <div className="column">
                    <h2 className="is-size-3">{props.feature.title}</h2>
                </div>
                <div className="column is-1 is-pull-right mt-3">
                    <button onClick={onEditButtonClicked}>
                        <FontAwesomeIcon icon={faEdit} />
                    </button > {' '}
                    < button onClick={confirmDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button >
                </div>
                {props.feature.status === 'Not Started' && (<div className="column is-2 mt-3">
                    <div className="field">
                        <input id="chkStartProgress" type="checkbox" name="chkStartProgress" className="switch" onChange={() => onFeatureStatusChanged('In Progress')} />
                        <label htmlFor="chkStartProgress">{getFormattedMessage("feature.start_progress", "Start Progress")}</label>
                    </div>
                </div>)}
                {props.feature.status === 'In Progress' && (<div className="column is-2 mt-3">
                    <div className="field">
                        <input id="chkComplete" type="checkbox" name="chkComplete" className="switch" onChange={() => onFeatureStatusChanged('Complete')} />
                        <label htmlFor="chkComplete">{getFormattedMessage("feature.complete_progress", "Complete")}</label>
                    </div>
                </div>)}
            </div>
            {showConfirmDelete &&
                <div className="column">
                    <ConfirmModal
                        title={getResourceById(state, "confirm.delete_message", "Confirm Delete")}
                        message={getResourceById(state, "feature.deletion_are_you_sure_message", "Are you sure you want to delete this feature?")}
                        okButtonText={getResourceById(state, "delete.button", "Delete")}
                        cancelButtonText={getResourceById(state, "cancel.button", "Cancel")}
                        onOk={onDeleteButtonClicked}
                        onCancel={() => setShowConfirmDelete(false)}
                    />
                </div>
            }
            {serverError &&
                <div className="column">
                    <div className="field">
                        <label style={{ color: '#ff0000' }}>{getFormattedMessage("feature.error_deletion", "Encountered server error when deleting feature.")} {serverErrorMsg}</label>
                    </div>
                </div>}
        </div>
    )
};

export default ResourceProvider(FeatureTitle);