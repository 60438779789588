import React, {useEffect} from 'react';

const BootstrapScriptsLoader = () => {
  useEffect(() => {
    const script1Id = "jquery-min-3.6.1";  
    let script1 = document.getElementById(script1Id);
    if (!!script1) {
      script1 = document.createElement("script");
      script1.src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js";
      script1.integrity="sha512-aVKKRRi/Q/YV+4mjoKBsE4x3H+BkegoM/em46NNlCqNTmUYADjBbeNefNxYV7giUp0VxICtqdrbqU7iVaeZNXA==";
      script1.crossorigin="anonymous";
      script1.referrerpolicy="no-referrer";
      script1.setAttribute("id", script1Id);  
      document.body.appendChild(script1); 
    }

    const script2Id = "bootstrap.min.4.6.2";  
    let script2 = document.getElementById(script2Id);
    if (!!script2) {
      script2 = document.createElement("script");
      script2.src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.6.2/js/bootstrap.min.js";
      script2.integrity="sha512-7rusk8kGPFynZWu26OKbTeI+QPoYchtxsmPeBqkHIEXJxeun4yJ4ISYe7C6sz9wdxeE1Gk3VxsIWgCZTc+vX3g==";
      script2.crossorigin="anonymous";
      script2.referrerpolicy="no-referrer";
      script2.setAttribute("id", script2Id);  
      document.body.appendChild(script2); 
    }

    return () => {
      script1 && document.body.removeChild(script1);
      script2 && document.body.removeChild(script2);
    };

  }, []);

  return <></>;
};

export default BootstrapScriptsLoader;