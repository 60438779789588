import { gql } from "@apollo/client";

export const SessionUser = gql`
  query {
    sessionUser {
      name
      token
    }
  }
`;

export const DATETIME_FORMATS = gql`
  query dateTimeFormats($timestamp: DateTime!){
    dateTimeFormats(timestamp: $timestamp){
      locale
      value
    }
  }
`

export const QUERY_PROJECTS = gql`
  query Projects(
    $cursor: String
    $searchTerm: String
    $limit: Int
    $sortBy: String
  ) {
    projects(
      cursor: $cursor
      searchTerm: $searchTerm
      limit: $limit
      sortBy: $sortBy
    ) {
      cursor
      hasMore
      projects {
        _id
        name
        budget
        currency
        user {
          _id
          name
        }
        createdAt
      }
    }
  }
`;

export const QUERY_PROJECT = gql`
  query Project($id: String!) {
    project(id: $id) {
      _id
      name
      budget
      currency
      user {
        _id
        name
      }
      createdAt
    }
  }
`;

export const QUERY_RELEASES = gql`
  query Releases(
    $cursor: String
    $searchTerm: String
    $limit: Int
    $sortBy: String
  ) {
    releases(
      cursor: $cursor
      searchTerm: $searchTerm
      limit: $limit
      sortBy: $sortBy
    ) {
      cursor
      hasMore
      releases {
        _id
        name
        description
        project {
          _id
          name
        }
        user {
          _id
          name
        }
        startDate
        endDate
      }
    }
  }
`;

export const QUERY_RELEASE = gql`
  query Release($id: String!) {
    release(id: $id) {
      _id
      name
      description
      project {
        _id
        name
      }
      user {
        _id
        name
      }
      startDate
      endDate
    }
  }
`;

export const QUERY_SERVICES = gql`
  query Services(
    $cursor: String
    $searchTerm: String
    $limit: Int
    $sortBy: String
  ) {
    services(
      cursor: $cursor
      searchTerm: $searchTerm
      limit: $limit
      sortBy: $sortBy
    ) {
      cursor
      hasMore
      services {
        _id
        name
        description
        project {
          _id
          name
        }
        user {
          _id
          name
        }
        createdAt
      }
    }
  }
`;

export const QUERY_SERVICE = gql`
  query Service($id: String!) {
    service(id: $id) {
      _id
      name
      description
      project {
        _id
        name
      }
      user {
        _id
        name
      }
      createdAt
    }
  }
`;

export const QUERY_FEATURES = gql`
  query Features(
    $cursor: String
    $searchTerm: String
    $limit: Int
    $sortBy: String
  ) {
    features(
      cursor: $cursor
      searchTerm: $searchTerm
      limit: $limit
      sortBy: $sortBy
    ) {
      cursor
      hasMore
      features {
        _id
        title
        description
        project {
          _id
          name
        }
        release {
          _id
          name
        }
        user {
          _id
          name
        }
        status
        startDate
        endDate
        attachments
      }
    }
  }
`;

export const QUERY_FEATURE = gql`
  query Feature($id: String!) {
    feature(id: $id) {
      _id
      title
      description
      project {
        _id
        name
      }
      release {
        _id
        name
      }
      user {
        _id
        name
      }
      status
      startDate
      endDate
      attachments
    }
  }
`;

export const QUERY_FEATURE_STATUS_COUNTS = gql`
  query FeatureStatusCounts {
    featureStatusCounts {
      currentYear {
        notStarted
        inProgress
        complete
      }
      overall {
        notStarted
        inProgress
        complete
      }
    }
  }
`;

export const QUERY_FEATURE_COUNTS = gql`
  query FeatureCounts($projectId: String!, $releaseId: String!) {
    featureCountForProject(projectId: $projectId) {
      count
    }
    featureCountForRelease(releaseId: $releaseId) {
      count
    }
  }
`;

export const IS_LOGGED_IN = gql`
  {
    isLoggedIn @client
  }
`;
