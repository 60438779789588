import React, { useContext } from 'react';
import _ from 'lodash';
import { IntlProvider, FormattedDate, FormattedMessage } from 'react-intl';
import Context from './../context';
import { FormattedNumber } from 'react-intl';

import DateField from './DateField/DateField';
import Currencies from './../currencies/symbols.json';

import Resources_EN from './../translations/en.json';
import Resources_ES from './../translations/es.json';
import Resources_FR from './../translations/fr.json';
import Resources_HI from './../translations/hi.json';
import Resources_IT from './../translations/it.json';
import Resources_PT from './../translations/pt.json';
import Resources_RU from './../translations/ru.json';
import Resources_UK from './../translations/uk.json';
import Resources_ZH from './../translations/zh.json';

// set the resources object that will be used for the translations.
const resources = {
  en: Resources_EN,
  es: Resources_ES,
  fr: Resources_FR,
  hi: Resources_HI,
  it: Resources_IT,
  pt: Resources_PT,
  ru: Resources_RU,
  uk: Resources_UK,
  zh: Resources_ZH,
};

const getResourceById = (state, resourceId, defaultValue) => { 
  const language = state.locale.value.split(/[-_]/)[0]; 
  if (!resources[language]) return defaultValue;  
  if (!resources[language][resourceId]) return defaultValue;
  return resources[language][resourceId];
}

const getCurrencyForCurrentLocale = (state) => {
  const matchedCurrencies = _.filter(Currencies, c => {
    return c['locale'].toLowerCase() === state.locale.value.toLowerCase();    
  });

  if (!!matchedCurrencies) {
    return matchedCurrencies[0];
  }

  return null;
}


const getFormattedMessage = (resourceId, defaultValue) => {
  return <FormattedMessage id={resourceId} defaultMessage={defaultValue} />;
}

const getFormattedCurrency = (value, currency) => {
  return (
    <FormattedNumber
      value={value} 
      style="currency" 
      currency={currency}
      currencyDisplay="code"
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      useGrouping={true}      
    />  
  )
}

const getFormattedDateTime = (state, dateISOString) => {
  return (
    <DateField 
        timestamp={dateISOString} 
        locale={state.locale.value} />
  );
}

const ResourceProvider = (Component) => (props) => {
  const { state, dispatch } = useContext(Context);
  const language = state.locale.value.split(/[-_]/)[0]; 
  return (
      <>
        <IntlProvider locale={language} messages={resources[language]}>
          <Component {...props} />
        </IntlProvider>
      </>
    )
};

export default ResourceProvider;
export { getCurrencyForCurrentLocale, getFormattedCurrency, getFormattedDateTime, getFormattedMessage, getResourceById };