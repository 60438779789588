import React, { useContext, useState } from "react";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IS_LOGGED_IN } from "./../../graphql/queries";
import Logo from "./../../assets/logo.png";
import Context from './../../context';
import { getCountriesInfo } from "./../../lib/intlutil";
import ResourceProvider, { getFormattedMessage } from "../ResourceProvider";

const TopNav = (props) => {
  const { state, dispatch } = useContext(Context);
  const { data, client } = useQuery(IS_LOGGED_IN);
  const [locale, setLocale] = useState(state.locale);
  const [showCountryMenu, setShowCountryMenu] = useState(false);
  const loggedIn = data && data.isLoggedIn;

  const handleLogout = () => {
    localStorage.clear();
    client.writeQuery({ query: IS_LOGGED_IN, data: { isLoggedIn: false } });
    props.history.push("/login");
  };

  const onBurgerClick = () => {
    props.setIsBurgerActive(!props.isBurgerActive);
  };

  // Internationalization countries info.
  const countries = getCountriesInfo();

  const onCountrySelected = (option) => {
    setLocale(option);
    dispatch({ type: "SET_LOCALE", payload: option });
  }

  const onCountryMenuClicked = () => {
    setShowCountryMenu(!showCountryMenu);
  }

  const isActiveClass = props.isBurgerActive ? " is-active" : "";
  const countryMenuStyle = showCountryMenu? { display: "block" } : { display: "none" };
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <img src={Logo} width="183" height="35" />
          <div className="dropdown is-active ml-4">
            <div className="dropdown-trigger">
              <button className="button" aria-haspopup="true" onClick={onCountryMenuClicked} aria-controls="dropdown-menu" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <img src={locale.flag} alt={locale.name}/>
                <span className="ml-3" >{locale.name}</span>
                <span className="icon is-small">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>      				  
            <div className="dropdown-menu" style={countryMenuStyle}>
              <div className="dropdown-content">
                {_.map(countries, (mCountry) => 
                  <a key={mCountry.name} 
                      className="dropdown-item"
                      onClick={() => onCountrySelected(mCountry)} 
                      style={{textDecoration: 'none', display:'flex', flexDirection:'row', alignItems:'center'}} >
                    <img src={mCountry.flag} alt={mCountry.name} />
                    <span className="ml-4">{mCountry.name}</span>
                  </a>
                )}
              </div>
            </div>
        </div>                           
        </div>
        <a role="button" className={`navbar-burger burger${isActiveClass}`} aria-label="menu" aria-expanded="false" data-target="navbarBasic" onClick={onBurgerClick}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasic" className="navbar-menu">
        <div className="navbar-start"></div>
        <div className="navbar-end">
          <div className="navbar-item">
            {loggedIn && (
              <div className="buttons">
                <Link to="/playground" className="button is-light">
                  <strong>{getFormattedMessage("html_playground.button", "HTML Playground")}</strong>
                </Link>
                <Link to="#" onClick={handleLogout} className="button is-light">
                  <strong>{getFormattedMessage("logout.button", "Logout")}</strong>
                </Link>
              </div>
            )}
            {!loggedIn && (
              <div className="buttons">
                <Link to="/playground" className="button is-primary">
                  <strong>{getFormattedMessage("html_playground.button", "HTML Playground")}</strong>
                </Link>
                <Link to="/signup" className="button is-primary">
                  <strong>{getFormattedMessage("signup.button", "Sign up")}</strong>
                </Link>
                <Link to="/login" className="button is-light">
                {getFormattedMessage("login.button", "Log in")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default withRouter(ResourceProvider(TopNav));
