import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_PROJECTS } from "./../../graphql/queries";

import ProjectsTable from "./ProjectsTable";

const Projects = (props) => {
  const [currentPage, _] = useState("projects");
  const [isBurgerActive, setIsBurgerActive] = useState(false);  
  const { loading, error, data } = useQuery(QUERY_PROJECTS);

  // console.log('Release: data -', data);
  if (error) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("projects.error_loading", "Encountered error when loading projects!")}          
        </label>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="field">
        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </div>
    );
  }

  if (data && data.projects && data.projects.projects) {
    return (
      <div>
        <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-4-tablet is-3-desktop is-2-widescreen">
              <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
            </div>
            <ProjectsTable projects={data.projects.projects} />
          </div>
        </div>
      </div>
    );
  }
};

export default defaultHeadComponent(ResourceProvider(Projects));
