import React from 'react';
import ReactDOM from 'react-dom';
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { typeDefs, resolver } from './resolvers';
import keys from './config/keys';
import './index.css';
import AppComponent from './components/App';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

import { IS_LOGGED_IN } from './graphql/queries';

const uri = keys.graphQLServer.uri;
const httpLink = createHttpLink({ uri });
const cache = new InMemoryCache();

// setContext sets a context on your operation, which is used by other links further down the chain.
// REFERENCE: https://www.apollographql.com/docs/link/links/context/
// alternately, we could also add middleware to handle authorization header.
// REFERENCE: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${localStorage.getItem('user-token')}` || '',
        }
    }
})

// Http link is a terminating link that fetches GraphQL results from a GraphQL endpoint over an http connection.
// The http link supports both POST and GET requests with the ability to change the http options on a per query basis. 
// This can be used for authentication, persisted queries, dynamic uris, and other granular updates.
// REFERENCE: https://www.apollographql.com/docs/link/links/http/
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    uri,
    cache,
    connectToDevTools: true,
    typeDefs,
    resolver,
});

// check for token in localstorage.
// this cache can be accessed anywhere within our application using the @client directive.
const data = {
    isLoggedIn: !!localStorage.getItem('user-token'),
}

// here we write to cache whether user is logged-in or not.
cache.writeQuery({ query: IS_LOGGED_IN, data });

// client store can be reset on logout. update the cache data when client gets restored to read latest value from localstorage.
client.onResetStore(() => {
    console.log('[App] client onResetStore was invoked. writing to cache data -', data);
    cache.writeQuery({ query: IS_LOGGED_IN, data });
});

const App = () => {
    return (
        <ApolloProvider client={client}>
            <HelmetProvider>
                <AppComponent client={client} />
            </HelmetProvider>
        </ApolloProvider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
