import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Context from './../../context';
import ResourceProvider, { getFormattedDateTime, getFormattedMessage, getResourceById } from "./../ResourceProvider";

import ConfirmModal from "./../Dialogs/ConfirmDialog";

import { DELETE_SERVICE_MUTATION } from "./../../graphql/mutations";
import { QUERY_SERVICES } from "./../../graphql/queries";

const ServicesTable = (props) => {
  const { state, dispatch } = useContext(Context);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");
  const [deleteService, { loading, error }] = useMutation(DELETE_SERVICE_MUTATION, {
    refetchQueries: [{ query: QUERY_SERVICES }],
    onCompleted: (data) => {
      const { success, message, services } = data.deleteService;
      if (!success) {
        setDeleteError(true);
        setDeleteErrorMsg(message);
        return;
      }

      if (error) {
        setDeleteError(true);
        setDeleteErrorMsg(getResourceById(state, "service.error_deletion", "Encountered server error when deleting service."));
        return;
      }

      props.history.push("/services");
    },
  });

  const confirmDelete = async (row) => {
    // console.log('confirmDelete | row =', row._id);
    setShowConfirmDelete(true);
    setSelectedRowId(row._id);
  };

  const executeEdit = async (row) => {
    // console.log('handleEdit | row =', row._id);
    props.history.push(`/services/edit/${row._id}`);
  };

  const handleDelete = () => {
    // console.log('handleDelete | selected row id =', selectedRowId);
    try {
      deleteService({
        variables: {
          id: selectedRowId,
        },
      });
      setShowConfirmDelete(false);
    } catch (err) {
      console.log("Error when deleting service. Error -", err);
      setDeleteError(true);
      setDeleteErrorMsg(getResourceById(state, "service.error_deletion", "Encountered server error when deleting service."));
      setShowConfirmDelete(false);
      return;
    }
  };

  const options = {
    sizePerPage: 15,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };
  const { SearchBar } = Search;
  const columns = [
    {
      dataField: "name",
      text: getResourceById(state, "service.field_service", "Service"),
    },
    {
      dataField: "project.name",
      text: getResourceById(state, "service.field_project", "Project"),
    },
    {
      dataField: "description",
      text: getResourceById(state, "service.field_description", "Description"),
    },
    {
      dataField: "user.name",
      text: getResourceById(state, "service.field_createdby", "Created By"),
    },
    {
      dataField: "createdAt",
      text: getResourceById(state, "service.field_createddate", "Created Date"),
      formatter: (cellContent, row) => {
        return (
          <>
            {getFormattedDateTime(state, cellContent)}
          </>
        );
      },
    },
    {
      dataField: "dummy_field",
      isDummyField: true,
      text: "",
      headerStyle: (col, colIdx) => {
        return { width: "100px", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            <button onClick={() => executeEdit(row)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>{" "}
            <button onClick={() => confirmDelete(row)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        );
      },
    },
  ];

  const searchBarPlaceholder = getResourceById(state, "search.placeholder", "Enter search text");
  //   console.log("showConfirmDelete =", showConfirmDelete);
  return (
    <div className="column">
      {showConfirmDelete && <ConfirmModal 
        title={getResourceById(state, "confirm.delete_message", "Confirm Delete")}
        message={getResourceById(state, "service.deletion_are_you_sure_message", "Are you sure you want to delete this service?")} 
        okButtonText={getResourceById(state, "delete.button", "Delete")}
        cancelButtonText={getResourceById(state, "cancel.button", "Cancel")}
        onOk={handleDelete} onCancel={() => setShowConfirmDelete(false)} />}
      {deleteError && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{getFormattedMessage("service.error_deletion", "Encountered server error when deleting service.")} {deleteErrorMsg}</label>
        </div>
      )}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">{getFormattedMessage("home.label", "Home")}</Link>
          </li>
          <li className="is-active">&nbsp;&nbsp;{getFormattedMessage("services.label", "Services")}</li>
        </ul>
      </nav>
      <div className="container is-fluid">
        <h2 className="title">
        {getFormattedMessage("services.label", "Services")}&nbsp;&nbsp;
          <Link to="/services/new">
            <svg className="bi bi-plus-circle" width="0.8em" height="0.8em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
              <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
              <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            </svg>
          </Link>
        </h2>
        <ToolkitProvider keyField="_id" data={props.services} columns={columns} search={{ defaultSearch: "" }}>
          {(props) => (
            <div>
              <span className="is-size-5">{getFormattedMessage("search.label", "Search")}:</span>
              <SearchBar style={{ width: "100%" }} className="input" placeholder={searchBarPlaceholder} {...props.searchProps} />
              <BootstrapTable {...props.baseProps} pagination={paginationFactory(options)} />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default withRouter(ResourceProvider(ServicesTable));
