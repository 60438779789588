import React from 'react';
import parseISO from "date-fns/parseISO";
import { FormattedRelativeTime } from 'react-intl';
import { differenceInDays } from 'date-fns';
import ResourceProvider from "./../ResourceProvider";

const FeaturesListItem = (props) => {

    const { title, description, status, startDate } = props.feature;
    return (
        <article className="media">
            <div className="media-content">
                <div className="content">
                    <p className="has-text-grey-dark">
                        <strong>{title}</strong> <small className="has-text-grey">{status}</small> <small className="has-text-grey-d">
                            <FormattedRelativeTime value={differenceInDays(parseISO(startDate), Date.now())} unit="day" />
                        </small>
                        <br />
                        <small>{description}</small>
                    </p>
                </div>
            </div>
        </article>
    )
};

export default ResourceProvider(FeaturesListItem);