import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { register } from './../serviceWorker';

const defaultHeadComponent = (Component) => (props) => {
  const fontAwesomeCSS = "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
  const openSansFont = "https://fonts.googleapis.com/css?family=Open+Sans:300,400,700";
  const bulmaCSS = "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.0/css/bulma.min.css";
  const bulmaSwitchCSS = "css/bulma-switch.min.css";
  const stylesCSS = "css/styles.css";
  const reactBootstrapTableCSS = "https://cdn.jsdelivr.net/npm/react-bootstrap-table-2@2.10.7/dist/react-bootstrap-table.min.css";

  useEffect(() => {
    // preload CSS files using a service worker.
    const cssFiles = [
      fontAwesomeCSS,
      openSansFont,
      bulmaCSS,
      bulmaSwitchCSS,
      stylesCSS,
      reactBootstrapTableCSS,
    ];

    const urls = cssFiles.map(cssFile => new URL(cssFile, window.location).href);
    register({ urls });
  }, []);

  return (
    <>
      <Helmet>
          <link rel="stylesheet" href={fontAwesomeCSS} />
          <link rel="stylesheet" href={openSansFont} />
          <link rel="stylesheet" href={bulmaCSS} integrity="sha256-aPeK/N8IHpHsvPBCf49iVKMdusfobKo2oxF8lRruWJg=" crossorigin="anonymous" />
          <link rel="stylesheet" href={bulmaSwitchCSS} />
          <link rel="stylesheet" href={stylesCSS} />
          <link rel="stylesheet" href={reactBootstrapTableCSS} />
      </Helmet>
      <Component {...props} />
    </>
  )
};

export default defaultHeadComponent;