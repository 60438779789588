import React, { useContext, useState } from "react";
import LoginForm from "./LoginForm.jsx";
import TopNav from "./../TopNav/TopNav";
import { Redirect } from "react-router-dom";
import Context from "./../../context";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const Login = () => {
  const { state } = useContext(Context);
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  return state.isAuth ? (
    <Redirect to="/" />
  ) : (
    <div>
      <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
      <section className="hero is-info welcome is-small">
        <div className="hero-body">
          <div className="container is-fluid">
            <div className="columns">&nbsp;</div>
          </div>
        </div>
      </section>
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-one-third">
            <p>&nbsp;</p>
          </div>
          <div className="column is-one-third">
            <section>
              <div className="container my-6">
                <h2 className="title">{getFormattedMessage("login.label", "Login")}</h2>
                <LoginForm />
              </div>
            </section>
          </div>
          <div className="column is-one-third">
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default defaultHeadComponent(ResourceProvider(Login));
