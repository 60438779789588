import React, { useContext, useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import Context from './../../context';
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

import { CREATE_FEATURE_MUTATION } from "./../../graphql/mutations";
import { QUERY_FEATURES } from "./../../graphql/queries";

const EditFeatureForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [title, setTitle] = useState((props.feature && props.feature.title) || "");
  const [description, setDescription] = useState((props.feature && props.feature.description) || "");
  const [project, setProject] = useState((props.feature && props.feature.project && props.feature.project._id) || "");
  const [release, setRelease] = useState((props.feature && props.feature.release && props.feature.release._id) || "");
  const [status, setStatus] = useState((props.feature && props.feature.status) || "");
  const [startDate, setStartDate] = useState((props.feature && new Date(props.feature.startDate)) || new Date());
  const [endDate, setEndDate] = useState((props.feature && new Date(props.feature.endDate)) || new Date());
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // validataion related state properties.
  const [showTitleValidation, setShowTitleValidation] = useState(false);
  const [showProjectValidation, setShowProjectValidation] = useState(false);
  const [showReleaseValidation, setShowReleaseValidation] = useState(false);

  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    const titleInvalid = validateResult && title.trim() === "";
    setShowTitleValidation(titleInvalid);
    if (titleInvalid) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.feature_title_required", "Error: Feature title is required.");
    } else if (title.trim().length > 250) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.field_name_max_chars_exceeded",
        "Error: The value you have entered exceeds the maximum character limit of 250 characters for this field. Please enter less than 250 characters.");
    }

    const projectInvalid = validateResult && (!project || project.trim() === "");
    setShowProjectValidation(projectInvalid);
    if (projectInvalid) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.feature_project_required", "Error: Feature project is required.");
    }

    const releaseInvalid = validateResult && (!release || release.trim() === "");
    setShowReleaseValidation(releaseInvalid);
    if (releaseInvalid) {
      validateResult = false;
      validationMsg = getResourceById(state, "error.feature_release_required", "Error: Feature release is required.");
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const [createFeature, { loading, error: createError }] = useMutation(CREATE_FEATURE_MUTATION, {
    refetchQueries: [{ query: QUERY_FEATURES }],
    onCompleted: (data) => {
      const { success, message, feature } = data.createFeature;
      if (!success) {
        setError(true);
        setErrorMsg(message);
        return;
      }

      if (createError) {
        setError(true);
        setErrorMsg(getResourceById(state, "feature.error_create_update", "Encountered server error when creating/updating feature."));
        return;
      }

      if (props.onSubmitClicked) {
        props.onSubmitClicked(feature);
        return;
      }

      props.history.push("/features");
    },
    onError: (error) => {
      setError(true);
      setErrorMsg(`${getResourceById(state, "error.encountered_server_error_message", "Encountered server error")}: ${error.message}`);
    },
  });

  const handleNewFeature = async (event) => {
    event.preventDefault();

    const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setError(true);
      setErrorMsg(validationMsg);
      return;
    }

    try {
      const isNew = !props.feature;
      // console.log('creating features -', {
      //     title, description, project, release, status, startDate, endDate, isNew
      // });

      createFeature({
        variables: {
          title,
          description,
          project,
          release,
          status,
          startDate,
          endDate,
          isNew,
        },
      });
    } catch (err) {
      console.log("Error when creating/updating feature. Error -", err);
      setError(true);
      setErrorMsg(getResourceById(state, "feature.error_create_update", "Encountered server error when creating/updating feature."));
      return;
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();

    if (props.onCancelClicked) {
      props.onCancelClicked();
      return;
    }

    props.history.push("/features");
  };

  let progressPerc = 0;
  if (props.feature) {
    progressPerc = props.feature.status === "Complete" ? 100 : props.feature.status === "In Progress" ? 50 : 0;
  }

  return (
    <div className="column">
      {props.showProgressBar && (
        <div className="box">
          <progress className="progress is-primary is-small" value={progressPerc} max="100">
            {progressPerc}%
          </progress>
        </div>
      )}
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{errorMsg}</label>
        </div>
      )}
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{getFormattedMessage("title.label", "Title")}</label>
        </div>
        <div className="field-body">
          <div className="field is-expanded">
            <p className="control is-expanded has-icons-left">
              <input onChange={(e) => setTitle(e.target.value)} value={title} className={showTitleValidation ? "input is-danger" : "input"} type="text" required="" disabled={!!props.feature} placeholder={getResourceById(state, "title.placeholder", "Enter title")} />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            </p>
            <p className={showTitleValidation ? "help is-danger" : "help"}>{getFormattedMessage("feature.title_is_required", "Title field is required")}</p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{getFormattedMessage("feature.field_description", "Description")}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded has-icons-left">
              <textarea className="textarea" type="text" value={description} required="" onChange={(e) => setDescription(e.target.value)} 
                placeholder={getResourceById(state, "feature.description_placeholder", "Provide additional details about the feature")} />
            </p>
            <p className="help">{getFormattedMessage("feature.description_is_optional", "Description field is optional")}</p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{getFormattedMessage("feature.field_project", "Project")}</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className={showProjectValidation ? "select is-fullwidth is-danger" : "select is-fullwidth"}>
                <select onChange={(e) => setProject(e.target.value)} defaultValue={project}>
                  <option value="">------------ {getResourceById(state, "select.project", "Select project")} ------------</option>
                  {_.map(props.projects, (p) => (
                    <option key={p._id} value={p._id}>
                      {p.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p className="help">{getFormattedMessage("feature.project_is_required", "Project for this feature. Project is required")}</p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{getFormattedMessage("feature.field_release", "Release")}</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className={showReleaseValidation ? "select is-fullwidth is-danger" : "select is-fullwidth"}>
                <select onChange={(e) => setRelease(e.target.value)} defaultValue={release}>
                  <option value="">------------ {getResourceById(state, "select.release", "Select release")} ------------</option>
                  {_.map(props.releases, (r) => (
                    <option key={r._id} value={r._id}>
                      {r.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <p className="help">{getFormattedMessage("feature.release_is_required", "Release for this feature. Release is required")}</p>
          </div>
        </div>
      </div>

      {props.feature && (
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{getFormattedMessage("feature.field_status", "Status")}</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select onChange={(e) => setStatus(e.target.value)} defaultValue={status}>
                    <option value="Not Started">{getResourceById(state, "feature.status_notstarted", "Not Started")}</option>
                    <option value="In Progress">{getResourceById(state, "feature.status_inprogress", "In Progress")}</option>
                    <option value="Complete">{getResourceById(state, "feature.status_complete", "Complete")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{getFormattedMessage("feature.field_attachments", "Attachments")}</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className="select">
                <div className="file">
                  <label className="file-label">
                    <input className="file-input" type="file" name="attachment" />
                    <span className="file-cta">
                      <span className="file-icon">
                        <FontAwesomeIcon icon={faUpload} />
                      </span>
                      <span className="file-label">{getFormattedMessage("fileattachment.choose_file_label", "Choose a file...")}</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <p className="help">{getFormattedMessage("attachment.demo_only_message", "File attachment is a demo-only feature. The application will upload no files to the server.",)}</p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label"></div>
        <div className="field-body">
          <div className="field is-grouped">
            <div className="control">
              <button onClick={handleNewFeature} className="button is-link">
                {getFormattedMessage("submit.button", "Submit")}
              </button>
            </div>
            <div className="control">
              <button type="reset" onClick={handleCancel} className="button is-link is-light">
              {getFormattedMessage("cancel.button", "Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResourceProvider(EditFeatureForm));
