import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import Context from './../../context';
import ResourceProvider, { getCurrencyForCurrentLocale, getFormattedMessage, getResourceById } from "./../ResourceProvider";

import { CREATE_PROJECT_MUTATION } from "./../../graphql/mutations";
import { QUERY_PROJECTS } from "./../../graphql/queries";

const EditProjectForm = (props) => {
  const { state, dispatch } = useContext(Context);
  const [name, setName] = useState((props.project && props.project.name) || "");
  const [budget, setBudget] = useState((props.project && props.project.budget) || "");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const validateForm = () => {
    let validateResult = true;
    let validationMsg = "";

    if (validateResult) {
      if (name.trim() === "") {        
        validateResult = false;
        validationMsg = getResourceById(state, "error.project_name_required", "Error: Project name is required.");
      } else if (name.trim().length > 250) {
        validateResult = false;
        validationMsg = getResourceById(state, "error.field_name_max_chars_exceeded",
          "Error: The value you have entered exceeds the maximum character limit of 250 characters for this field. Please enter less than 250 characters.");
      }
    }

    if (validateResult && `${budget}`.trim() === "") {
      validateResult = false;
      validationMsg = getResourceById(state, "error.project_budget_required", "Error: Project budget is required.");
    }

    return {
      validateResult,
      validationMsg,
    };
  };

  const [createProject, { loading, error: createError }] = useMutation(CREATE_PROJECT_MUTATION, {
    refetchQueries: [{ query: QUERY_PROJECTS }],
    onCompleted: (data) => {
      const { success, message, project } = data.createProject;
      if (!success) {
        setError(true);
        setErrorMsg(message);
        return;
      }

      if (createError) {
        setError(true);
        setErrorMsg(getResourceById(state, "project.error_create_update", "Encountered server error when creating/updating project."));
        return;
      }

      props.history.push("/projects");
    },
    onError: (error) => {
      setError(true);
      setErrorMsg(`${getResourceById(state, "error.encountered_server_error_message", "Encountered server error")}: ${error.message}`);
    },
  });

  const handleEditProject = async (event) => {
    event.preventDefault();

    const { validateResult, validationMsg } = validateForm();
    if (!validateResult) {
      setError(true);
      setErrorMsg(validationMsg);
      return;
    }

    const currency = getCurrencyForCurrentLocale(state);
    if (currency == null) {
      setError(true);
      setErrorMsg(getResourceById(state, "project.error_could_not_load_currency_for_locale", "Could not load currency for the selected locale."));
      return;
    }

    try {
      const isNew = !props.project;
      createProject({
        variables: {
          name,
          budget: parseFloat(budget),
          currency: currency.currencyCode,
          isNew,
        },
      });
    } catch (err) {
      console.log("Error when creating/updating project. Error -", err);
      setError(true);
      setErrorMsg(getResourceById(state, "project.error_create_update", "Encountered server error when creating/updating project."));
      return;
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    props.history.push("/projects");
  };

  const currency = getCurrencyForCurrentLocale(state);
  return (
    <form>
      {error && (
        <div className="field">
          <label style={{ color: "#ff0000" }}>{errorMsg}</label>
        </div>
      )}
      <div className="field">
        <label className="label">{getFormattedMessage("name.label", "Name")}</label>
        <div className="control">
          <input onChange={(e) => setName(e.target.value)} value={name} className="input" type="text" required="" disabled={!!props.project} placeholder={getResourceById(state, "name.placeholder", "Enter Name")} />
        </div>
        <p className="help">{getFormattedMessage("project.name_is_required", "Project name is required")}</p>
      </div>
      <div className="field">
        <label className="label">{getFormattedMessage("budget.label", "Budget")} - {currency["symbol"]} ({currency["currencyCode"]})</label>
        <div className="control">
          <input onChange={(e) => setBudget(e.target.value)} value={budget} className="input" type="number" required="" placeholder={getResourceById(state, "budget.placeholder", "Enter budget in your currency")} />
        </div>
        <p className="help">{getFormattedMessage("project.budget_is_required", "Project budget is required")}</p>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <button onClick={handleEditProject} className="button is-link">
            {getFormattedMessage("submit.button", "Submit" )}
          </button>
        </div>
        <div className="control">
          <button type="reset" onClick={handleCancel} className="button is-link is-light">
          {getFormattedMessage("cancel.button", "Cancel" )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default withRouter(ResourceProvider(EditProjectForm));
