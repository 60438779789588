import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EditProjectForm from './EditProjectForm';
import LeftMenu from './../LeftMenu/LeftMenu';
import TopNav from './../TopNav/TopNav';
import ResourceProvider, {getFormattedMessage} from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const NewProject = () => {
    const [currentPage, setCurrentPage] = useState("projects");
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    return (
        <div>
            <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-4-tablet is-3-desktop is-2-widescreen">
                        <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
                    </div>
                    <div className="column">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li><Link to="/">{getFormattedMessage("home.label", "Home")}</Link></li>
                                <li><Link to="/projects" aria-current="page">{getFormattedMessage("projects.label", "Projects")}</Link></li>
                                <li className="is-active">&nbsp;&nbsp;{getFormattedMessage("new.label", "New")}</li>
                            </ul>
                        </nav>
                        <div className="container is-fluid">
                            <section>
                                <div>
                                    <h2 className="title">{getFormattedMessage("new.project_label", "New Project")}</h2>
                                    <EditProjectForm />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default defaultHeadComponent(ResourceProvider(NewProject));
