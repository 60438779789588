import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, Route } from 'react-router-dom';
import { IS_LOGGED_IN } from './../graphql/queries';

// ProtectedRoute is a HOC which checks for authentication state and redirects to login screen if NOT authenticated.
const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { data } = useQuery(IS_LOGGED_IN);
    // console.log("ProtectedRoute | data =", data);
    const loggedIn = data && data.isLoggedIn;
    // console.log("ProtectedRoute | loggedIn =", loggedIn);
    return (
        <Route
            render={props => !loggedIn ? <Redirect to="/login" /> : <Component {...props} />}
            {...rest}
        />
    )
}

export default ProtectedRoute;