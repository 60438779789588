import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import ResourceProvider, { getFormattedMessage } from "../ResourceProvider";

const LeftMenu = (props) => {

  const asideClass = props.isBurgerActive ? '' : ' is-hidden-mobile'
  return (
    <aside className={`menu${asideClass}`}>
      <p className="menu-label">{getFormattedMessage("menu.label_main", "Main")}</p>
      <ul className="menu-list">
        <li>
          <NavLink
            className={
              props.activeMenu == "dashboard" ? "is-active" : ""
            }
            to="/"
          >
            {getFormattedMessage("menu.label_dashboard", "Dashboard")}{" "}
          </NavLink>{" "}
        </li>
        <li>
          <NavLink
            className={props.activeMenu == "projects" ? "is-active" : ""}
            to="/projects"
          >
            {getFormattedMessage("menu.label_projects", "Projects")}
          </NavLink>{" "}
        </li>
        <li>
          <NavLink
            className={props.activeMenu == "services" ? "is-active" : ""}
            to="/services"
          >
            {getFormattedMessage("menu.label_services", "Services")}
          </NavLink>{" "}
        </li>
        <li>
          <NavLink
            className={props.activeMenu == "releases" ? "is-active" : ""}
            to="/releases"
          >
            {getFormattedMessage("menu.label_releases", "Releases")}
          </NavLink>
        </li>
        <li>
          <NavLink
            className={props.activeMenu == "features" ? "is-active" : ""}
            to="/features"
          >
            {getFormattedMessage("menu.label_features", "Features")}
          </NavLink>
        </li>
      </ul>
      <p className="menu-label">{getFormattedMessage("menu.label_settings", "Settings")}</p>
      <ul className="menu-list">
        <li>
          <NavLink
            className={props.activeMenu == "settings" ? "is-active" : ""}
            to="/settings"
          >
            {getFormattedMessage("menu.label_settings", "Settings")}
          </NavLink>
        </li>
      </ul>
    </aside>
  );
}

export default withRouter(ResourceProvider(LeftMenu));
