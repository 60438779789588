import React from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import EditFeatureForm from "./EditFeatureForm";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";

import { QUERY_PROJECTS, QUERY_RELEASES } from "./../../graphql/queries";

const EditFeature = (props) => {
  const { loading: loadingP, error: errorP, data: dataP } = useQuery(
    QUERY_PROJECTS
  );
  const { loading: loadingR, error: errorR, data: dataR } = useQuery(
    QUERY_RELEASES
  );
  // console.log('Projects: data -', dataP);
  // console.log('Releases: data -', dataR);

  if (errorP) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("projects.error_loading", "Encountered error when loading projects!")}
        </label>
      </div>
    );
  }

  if (errorR) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("releases.error_loading", "Encountered error when loading releases!")}
        </label>
      </div>
    );
  }

  if (loadingP || loadingR) {
    return (
      <div className="field">
        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </div>
    );
  }

  let projects = dataP && dataP.projects && dataP.projects.projects;
  if (projects) {
    projects = _.sortBy(projects, ["name"]);
  }

  let releases = dataR && dataR.releases && dataR.releases.releases;
  if (releases) {
    releases = _.sortBy(releases, ["name"]);
  }

  return <EditFeatureForm {...props} projects={projects} releases={releases} />;
};

export default ResourceProvider(EditFeature);
