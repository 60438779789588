import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import EditReleaseForm from './EditReleaseForm';
import LeftMenu from './../LeftMenu/LeftMenu';
import TopNav from './../TopNav/TopNav';
import ResourceProvider, {getFormattedMessage} from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_PROJECTS } from './../../graphql/queries';

const NewRelease = () => {
    const [currentPage, setCurrentPage] = useState("releases");
    const [isBurgerActive, setIsBurgerActive] = useState(false);
    const { loading, error, data } = useQuery(QUERY_PROJECTS);

    if (error) return <div>{getFormattedMessage("error.encountered_error_message", "Encountered error")} - {error}</div>

    return (
        <div>
            <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-4-tablet is-3-desktop is-2-widescreen">
                        <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
                    </div>
                    <div className="column">
                        <nav className="breadcrumb" aria-label="breadcrumbs">
                            <ul>
                                <li><Link to="/">{getFormattedMessage("home.label", "Home")}</Link></li>
                                <li><Link to="/releases" aria-current="page">{getFormattedMessage("releases.label", "Releases")}</Link></li>
                                <li className="is-active">&nbsp;&nbsp;{getFormattedMessage("new.label", "New")}</li>
                            </ul>
                        </nav>
                        <div className="container is-fluid">
                            <section>
                                <div>
                                    <h2 className="title">{getFormattedMessage("new.release_label", "New Release")}</h2>
                                    {loading ? <div>{getFormattedMessage("loading.message", "loading...")}</div> : <EditReleaseForm projects={data.projects && data.projects.projects} />}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default defaultHeadComponent(ResourceProvider(NewRelease));
