import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import Statistics from "./../Statistics/Statistics";
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";
import { QUERY_FEATURE_COUNTS } from "./../../graphql/queries";
import Context from "../../context";

const FeatureStatistics = (props) => {
  const { state, dispatch } = useContext(Context);
  const { projectId, releaseId } = props;
  const { loading, error, data } = useQuery(QUERY_FEATURE_COUNTS, {
    variables: {
      projectId,
      releaseId,
    },
    fetchPolicy: "network-only", // fetch policy set to 'network-only' to ensure post mutation (eg. edit or new), latest values are fetched from DB.
  });

  if (error) {
    return (
      <nav className="level mt-6 pl-2">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("features.error_loading_statistics", "Encountered error when loading feature statistics!")}
        </label>
      </nav>
    );
  }

  if (loading) {
    return (
      <nav className="level mt-6 pl-2">
        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </nav>
    );
  }

  const projectCount = data.featureCountForProject.count;
  const releaseCount = data.featureCountForRelease.count;

  return (
    <React.Fragment>
      <nav className="level mt-6 pl-2">
        <Statistics
          title={getResourceById(state, "features.total_features_in_project", "Total Features In Project")}
          count={projectCount}
          buttonTitle={getResourceById(state, "features.show_project", "Show Project")}
          buttonToLink={`/projects/edit/${projectId}`}
        />
        <Statistics
          title={getResourceById(state, "features.total_features_in_release", "Total Features In Release")}
          count={releaseCount}
          buttonTitle={getResourceById(state, "features.show_release", "Show Release")}
          buttonToLink={`/releases/edit/${releaseId}`}
        />
      </nav>
    </React.Fragment>
  );
};

export default ResourceProvider(FeatureStatistics);
