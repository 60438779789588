import React from "react";
import ResourceProvider from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

const Terms = () => {
  return (
    <div class="content">
      <section className="section">
        <h1 className="title">Website Terms and Conditions of Use</h1>
        <h2 className="subtitle mt-3">1. Terms</h2>
        <p>By accessing this Website, accessible from https://featurematrix.devops-automation.net, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>
        <h2 className="subtitle mt-3">2. Use License</h2>
        <p>Permission is granted to use materials on DevOps-Automation.net's Website for personal, non-commercial, educational purposes. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
        <ul>
          <li>modify or copy the materials;</li>
          <li>use the materials for any commercial purpose or for any public display;</li>
          <li>attempt to reverse engineer any software contained on DevOps-Automation.net's Website;</li>
          <li>remove any copyright or other proprietary notations from the materials; or</li>
          <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        <p>This will let DevOps-Automation.net to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>
        <h2 className="subtitle mt-3">3. Disclaimer</h2>
        <p>All the materials on DevOps-Automation.net’s Website are provided "as is". DevOps-Automation.net makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, DevOps-Automation.net does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
        <h2 className="subtitle mt-3">4. Limitations</h2>
        <p>DevOps-Automation.net or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on DevOps-Automation.net’s Website, even if DevOps-Automation.net or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
        <h2 className="subtitle mt-3">5. Revisions and Errata</h2>
        <p>The materials appearing on DevOps-Automation.net’s Website may include technical, typographical, or photographic errors. DevOps-Automation.net will not promise that any of the materials in this Website are accurate, complete, or current. DevOps-Automation.net may change the materials contained on its Website at any time without notice. DevOps-Automation.net does not make any commitment to update the materials.</p>
        <h2 className="subtitle mt-3">6. Links</h2>
        <p>DevOps-Automation.net has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by DevOps-Automation.net of the site. The use of any linked website is at the user’s own risk.</p>
        <h2 className="subtitle mt-3">7. Site Terms of Use Modifications</h2>
        <p>DevOps-Automation.net may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
        <h2 className="subtitle mt-3">8. Your Privacy and Data</h2>
        <p>DevOps-Automation.net makes no guarantees about your data privacy or can be held accountable for loss of data associated with your account, when using this website.</p>
        <h2 className="subtitle mt-3">9. Governing Law</h2>
        <p>Any claim related to DevOps-Automation.net's Website shall be governed by the laws of us without regards to its conflict of law provisions.</p>{" "}
      </section>
    </div>
  );
};

export default defaultHeadComponent(ResourceProvider(Terms));
