import React from 'react';
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";

const ConfirmDialog = (props) => {
    const handleOnOk = (event) => {
        event.preventDefault();
        if (props.onOk) {
            props.onOk(event);
        }
    }

    const handleOnCancel = (event) => {
        event.preventDefault();
        if (props.onCancel) {
            props.onCancel(event);
        }
    }

    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-content">
                <article className="message">
                    <div className="message-header">
                        <p>{props.title || getFormattedMessage("modal.confirm_action", "Confirm Action")}</p>
                    </div>
                    <div className="message-body">
                        {props.message || getFormattedMessage("modal.are_you_sure_message", "Are you sure you want to perform this action?")}
                        <br /><br />
                        <button className="button is-danger" onClick={handleOnOk}>{props.okButtonText || getFormattedMessage("modal.ok_button", "OK")}</button>
                        &nbsp;<button className="button" onClick={handleOnCancel}>{props.cancelButtonText || getFormattedMessage("modal.cancel_button", "Cancel")}</button>
                    </div>
                </article>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={handleOnCancel}></button>
        </div>
    );
}

export default ResourceProvider(ConfirmDialog);