import _ from 'lodash';

import keys from './../config/keys';

import FlagAustralia from "./../assets/Australia.png";
import FlagBrazil from "./../assets/Brazil.png";
import FlagCanada from "./../assets/Canada.png";
import FlagChina from "./../assets/China.png";
import FlagFrance from "./../assets/France.png";
import FlagIndia from "./../assets/India.png";
import FlagItaly from "./../assets/Italy.png";
import FlagRussia from "./../assets/Russia.png";
import FlagSpain from "./../assets/Spain.png";
import FlagUkraine from "./../assets/Ukraine.png";
import FlagUnitedKingdom from "./../assets/UnitedKingdom.png";
import FlagUnitedStates from "./../assets/UnitedStates.png";

const getCurrentLocale = () => {
    if (!!navigator) {
        // browser's language
        const language = navigator.language || navigator.userLanguage;
        if (!!language) {
            const [locale, region] = language.split('-');
    
            // if region present, return it with the locale
            if (region) {
                return `${locale}-${region}`;
            }
        
            // return only the locale
            return locale;
        }
    }

    return 'en-US';
};

export const getCountriesInfo = () => {
  // Ensure the following:
  // 1. Locale codes (eg. en-AU, en-CA, en-GB, en-US) used here should match the locales registered in App.js using registerLocale() method.
  // 2. Locale codes used here and in App.js are in language-REGION format. Refer here - https://icu4c-demos.unicode.org/icu-bin/locexp
  // 3. For every unique language (eg. en, es), there should be a .JSON file in 'translations' folder with the resource strings,
  //    and resource JSON for each of these unique languages should be registered in ResourceProvider.js
  return [
    { name: "Australia", value: "en-AU", flag: FlagAustralia },
    { name: "Brazil", value: "pt-BR", flag: FlagBrazil },
    { name: "Canada", value: "en-CA", flag: FlagCanada },
    { name: "China", value: "zh-CN", flag: FlagChina },
    { name: "France", value: "fr-FR", flag: FlagFrance },
    { name: "India", value: "hi-IN", flag: FlagIndia },
    { name: "Italy", value: "it-IT", flag: FlagItaly },
    { name: "Russia", value: "ru-RU", flag: FlagRussia },
    { name: "Spain", value: "es-ES", flag: FlagSpain },
    { name: "Ukraine", value: "uk-UA", flag: FlagUkraine },
    { name: "United Kingdom", value: "en-GB", flag: FlagUnitedKingdom },
    { name: "United States", value: "en-US", flag: FlagUnitedStates },
  ];
}

export const getCurrentLocaleCountryInfo = () => {
    const countries = getCountriesInfo();
    if (keys.options.turnOffCurrentLocaleDetection) {
        return _.find(countries, {value: "en-US"});
    }

    // Locale detection enabled. detect current locale and return matching country info.
    const locale = getCurrentLocale();
    let matchingCountry = _.find(countries, {value: locale}); 
    if(!matchingCountry) {
        const extendedCountries = _.map(countries, (country) => {
            return {
                ...country,
                valuePrefix: country.value.split('-')[0],
            }
        });

        matchingCountry = _.find(extendedCountries, {valuePrefix: locale.split('-')[0]});
    }

    if (!matchingCountry) {
        return _.find(countries, {value: "en-US"});
    }
    
    return matchingCountry;
};