import React, { useContext } from "react";
import Context from './../../context';
import { PieChart, Pie, Legend } from "recharts";
import { useQuery } from "@apollo/client";
import { QUERY_FEATURE_STATUS_COUNTS } from "./../../graphql/queries";
import ResourceProvider, { getFormattedMessage, getResourceById } from "./../ResourceProvider";

const DashboardCharts = (props) => {
  const { state, dispatch } = useContext(Context);
  
  const { loading, error, data } = useQuery(QUERY_FEATURE_STATUS_COUNTS, {
    fetchPolicy: "network-only",
  });

  if (error) {
    return (
      <div className="column is-one-half">
        <h2 className="title">{getFormattedMessage("dashboard.charts_features_status", "Features Status")}</h2>
        <footer className="card-footer">
          <label style={{ color: "#ff0000" }}>
            {getFormattedMessage("dashboard.charts_error_loading_charts", "Encountered error when loading release charts!")}
          </label>
        </footer>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="column is-one-half">
        <h2 className="title">{getFormattedMessage("dashboard.charts_features_status", "Features Status")}</h2>
        <footer className="card-footer">
          <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
        </footer>
      </div>
    );
  }

  const { featureStatusCounts } = data;

  const outerPieData = [
    {
      name: getResourceById(state, "dashboard.charts_status_notstarted", "Not Started"),
      value: featureStatusCounts.currentYear.notStarted,
      fill: "#B61F12",
    },
    {
      name: getResourceById(state, "dashboard.charts_status_inprogress", "In-Progress"),
      value: featureStatusCounts.currentYear.inProgress,
      fill: "#F96B0E",
    },
    {
      name: getResourceById(state, "dashboard.charts_status_complete", "Complete"),
      value: featureStatusCounts.currentYear.complete,
      fill: "#4E843A",
    },
  ];
  const innerPieData = [
    {
      name: getResourceById(state, "dashboard.charts_status_notstarted", "Not Started"),
      value: featureStatusCounts.overall.notStarted,
      fill: "#B61F12",
    },
    {
      name: getResourceById(state, "dashboard.charts_status_inprogress", "In-Progress"),
      value: featureStatusCounts.overall.inProgress,
      fill: "#F96B0E",
    },
    {
      name: getResourceById(state, "dashboard.charts_status_complete", "Complete"),
      value: featureStatusCounts.overall.complete,
      fill: "#4E843A",
    },
  ];

  return (
    <div className="column is-one-half">
      <h2 className="title">{getFormattedMessage("dashboard.charts_features_status", "Features Status")}</h2>
      <nav className="panel">
        <p className="panel-heading">{getFormattedMessage("dashboard.charts_overall_status", "Overall Status")} - {getFormattedMessage("dashboard.charts_status_this_year", "Status This Year")}</p>
        <PieChart width={530} height={530} style={{ paddingLeft: "100px" }}>
          <Pie
            data={outerPieData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
            legendType="none"
          />
          <Pie
            data={innerPieData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={150}
            outerRadius={190}
            fill="#82ca9d"
            label
            legendType="rect"
          />
          <Legend verticalAlign="bottom" />
        </PieChart>
      </nav>
    </div>
  );
};

export default ResourceProvider(DashboardCharts);
