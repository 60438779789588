import React, { useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import Context from './../../context';
import { truncateText } from "./../../lib/textutil";
import ResourceProvider, { getFormattedDateTime, getFormattedMessage, getResourceById } from "./../ResourceProvider";

import { QUERY_RELEASES } from "./../../graphql/queries";

const DashboardReleases = (props) => {
  const { state, dispatch } = useContext(Context);
  const { loading, error, data } = useQuery(QUERY_RELEASES);

  const maxDisplayCount = 4;
  const maxTitleLength = 50;
  const maxDescriptionLength = 150;

  if (error) {
    return (
      <div className="column is-one-half">
        <h2 className="title">{getFormattedMessage("dashboard.releases_label", "Releases")}</h2>
        <footer className="card-footer">
          <Link to="#" className="card-footer-item">
            <label style={{ color: "#ff0000" }}>
              {getFormattedMessage("dashboard.releases_error_loading_releases", "Encountered error when loading releases!")}
            </label>
          </Link>
        </footer>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="column is-one-half">
        <h2 className="title">{getFormattedMessage("dashboard.releases_label", "Releases")}</h2>
        <footer className="card-footer">
          <Link to="#" className="card-footer-item">
            <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
          </Link>
        </footer>
      </div>
    );
  }

  const renderReleases = () => {
    if (data && data.releases && data.releases.releases) {
      let displayReleases = data.releases.releases;
      if (displayReleases.length > maxDisplayCount) {
        displayReleases = _.slice(displayReleases, 0, maxDisplayCount);
      }

      return _.map(displayReleases, (release) => {
        return (
          <div className="card" key={release._id}>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">
                    {truncateText(release.name, maxTitleLength)}
                  </p>
                  <p className="subtitle is-6 mt-1">
                    @ <time>{getFormattedDateTime(state, release.startDate)}</time>{" "}
                    → <time>{getFormattedDateTime(state, release.endDate)}</time>
                  </p>
                </div>
              </div>
              <div className="content">
                {!release.description.trim() && (
                  <i>
                    <small>{"<" + getResourceById(state, "dashboard.releases_message_release_has_no_description", "this release has no description") + ">"}</small>
                  </i>
                )}
                {release.description &&
                  truncateText(release.description, maxDescriptionLength)}
                <br />
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="is-5">{getFormattedMessage("dashboard.releases_message_no_releases_found", "No releases were found")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="column is-one-half">
      <h2 className="title">{getFormattedMessage("dashboard.releases_label", "Releases")}</h2>
      {renderReleases()}
      {data &&
        data.releases &&
        (data.releases.hasMore ||
          data.releases.releases.length > maxDisplayCount) && (
          <footer className="card-footer">
            <Link to="/releases" className="card-footer-item">
            {getFormattedMessage("view_all.label", "View All")}
            </Link>
          </footer>
        )}
    </div>
  );
};

export default withRouter(ResourceProvider(DashboardReleases));
