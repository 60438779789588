import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { withRouter, Link } from "react-router-dom";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";

import FeatureTitle from "./FeatureTitle";
import FeaturesList from "./FeaturesList";
import ViewFeature from "./ViewFeature";
import EditFeature from "./EditFeature";
import ResourceProvider, {getFormattedMessage} from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_FEATURES } from "./../../graphql/queries";

const Features = (props) => {
  const [currentPage, _] = useState("features");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [refetchedFeatures, setRefetchedFeatures] = useState(null);
  const [isBurgerActive, setIsBurgerActive] = useState(false);

  const { loading, error, data, fetchMore, refetch, updateQuery } = useQuery(
    QUERY_FEATURES,
    {
      variables: {
        limit: 10,
      },
      fetchPolicy: "network-only", // fetch policy set to 'network-only' to ensure post mutation (eg. edit or new), latest values are fetched from DB.
    }
  );
  // console.log('Features: data -', data);
  // console.log('Features: refetchedFeatures -', refetchedFeatures);

  if (error) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("features.error_loading", "Encountered error when loading features!")}
        </label>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="field">

        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </div>
    );
  }

  const onEditButtonClicked = () => {
    setIsEditMode(true);
  };

  const refetchComponentData = async () => {
    const { data } = await refetch({ limit: 10 });
    setRefetchedFeatures(data.features);
    if (
      data.features &&
      data.features.features &&
      data.features.features.length > 0
    ) {
      setSelectedFeature(data.features.features[0]);
    } else {
      setSelectedFeature(null);
    }
  };

  const onDeleteButtonClicked = async () => {
    await refetchComponentData();
  };

  const onStatusUpdated = async () => {
    await refetchComponentData();
  };

  const onFeatureSelected = (feature) => {
    setSelectedFeature(feature);
    setIsEditMode(false);
  };

  const onEditFeatureSubmitClicked = async (feature) => {
    await refetchComponentData();
    // console.log('onEditFeatureSubmitClicked | setSelectedFeature =', feature);
    onFeatureSelected(feature);
  };

  const onEditFeatureCancelClicked = () => {
    setIsEditMode(false);
  };

  if (data && data.features && data.features.features) {
    return (
      <div>
        <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-4-tablet is-3-desktop is-2-widescreen">
              <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
            </div>
            <div className="column">
              <nav className="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <Link to="/">{getFormattedMessage("home.label", "Home")}</Link>
                  </li>
                  <li className="is-active">&nbsp;&nbsp;{getFormattedMessage("features.label", "Features")}</li>
                </ul>
              </nav>
              <div className="container is-fluid">
                <div className="columns">
                  <div className="column is-4">
                    <h2 className="title">
                      {getFormattedMessage("features.label", "Features")}&nbsp;&nbsp;{" "}
                      <Link to="/features/new">
                        <svg
                          className="bi bi-plus-circle"
                          width="0.8em"
                          height="0.8em"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                        </svg>
                      </Link>
                    </h2>
                  </div>
                  {(selectedFeature || data.features.features.length > 0) && (
                    <FeatureTitle
                      feature={selectedFeature || data.features.features[0]}
                      onEditButtonClicked={onEditButtonClicked}
                      onDeleteButtonClicked={onDeleteButtonClicked}
                      onStatusUpdated={onStatusUpdated}
                    />
                  )}
                </div>
                <div className="columns">
                  {selectedFeature || data.features.features.length > 0 ? (
                    <FeaturesList
                      features={data.features}
                      refetchedFeatures={refetchedFeatures}
                      onFeatureSelected={onFeatureSelected}
                      fetchMore={fetchMore}
                      refetch={refetch}
                      updateQuery={updateQuery}
                    />
                  ) : (
                    <span>{getFormattedMessage("features.no_feature_present", "No features present.")}</span>
                  )}
                  {(selectedFeature || data.features.features.length > 0) &&
                    !isEditMode && (
                      <ViewFeature
                        feature={selectedFeature || data.features.features[0]}
                      />
                    )}
                  {(selectedFeature || data.features.features.length > 0) &&
                    isEditMode && (
                      <EditFeature
                        feature={selectedFeature || data.features.features[0]}
                        showProgressBar={true}
                        onSubmitClicked={onEditFeatureSubmitClicked}
                        onCancelClicked={onEditFeatureCancelClicked}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(defaultHeadComponent(ResourceProvider(Features)));
