import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import TopNav from "./../TopNav/TopNav";
import LeftMenu from "./../LeftMenu/LeftMenu";
import ResourceProvider, { getFormattedMessage } from "./../ResourceProvider";
import defaultHeadComponent from "./../defaultHeadComponent";

import { QUERY_SERVICES } from "./../../graphql/queries";

import ServicesTable from "./ServicesTable";

const Services = (props) => {
  const [currentPage, _] = useState("services");
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const { loading, error, data } = useQuery(QUERY_SERVICES);

  // console.log('Release: data -', data);

  if (error) {
    return (
      <div className="field">
        <label style={{ color: "#ff0000" }}>
          {getFormattedMessage("services.error_loading", "Encountered error when loading services!")}
        </label>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="field">
        <label style={{ color: "#565656" }}>{getFormattedMessage("loading.message", "loading...")}</label>
      </div>
    );
  }

  if (data && data.services && data.services.services) {
    return (
      <div>
        <TopNav isBurgerActive={isBurgerActive} setIsBurgerActive={setIsBurgerActive} />
        <div className="container is-fluid">
          <div className="columns">
            <div className="column is-4-tablet is-3-desktop is-2-widescreen">
              <LeftMenu activeMenu={currentPage} isBurgerActive={isBurgerActive} />
            </div>
            <ServicesTable services={data.services.services} />
          </div>
        </div>
      </div>
    );
  }
};

export default defaultHeadComponent(ResourceProvider(Services));
